<template>
  <div>
    <b-skeleton type="input" v-if="fetchData"></b-skeleton>
    <div class="classTypes" v-else>
      <b-overlay
        :show="classLoading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <b-form @submit.prevent="attachClassType()" class="border-0">
          <b-form-radio-group
            :options="classTypesOptions"
            v-model="selectedClass"
          ></b-form-radio-group>

          <b-form-input
            class="mt-3"
            v-if="selectedClass == 3"
            v-model="bridgeClassInfo"
            placeholder="Bridge Class Info"
          ></b-form-input>
          <b-button
            type="submit"
            class="mt-3"
            variant="primary"
            v-if="attendenceList"
            :disabled="classBtn.disabled"
            >{{ classBtn.text }}</b-button
          >
        </b-form>
      </b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  props: ["classTypeId", "attendanceId", "attendenceList", "muliple"],
  async created() {
    this.fetchData = true;
    await this.getClassTypes();
    if (!this.muliple) {
      await this.getClasstypeOfAttendence();
    }
    this.fetchData = false;
  },

  data() {
    return {
      fetchData: false,
      classLoading: false,
      classTypesOptions: [],
      classTypesOptionsObj: {},
      bridgeClassInfo: "",
      selectedClass: null,
      classBtn: {
        text: "Submit",
        disabled: false,
      },
    };
  },
  methods: {
    async attachClassType() {
      if (this.attendenceList) {
        this.classLoading = true;
        this.classBtn = { text: "Please wait...", disabled: true };
      }
      const postData = {
        classtype_id: this.selectedClass,
        topic: this.selectedClass == 3 ? this.bridgeClassInfo : "",
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancedates/${this.attendanceId}/attach/classtypes`;
      await this.$axios.post(url, postData).then((res) => {
        const className =
          this.selectedClass == null
            ? "Regular"
            : this.classTypesOptionsObj[this.selectedClass];
        this.$toast.success(`${className} attached`, {
          position: "top",
          duration: 3000,
        });
        this.$emit("classTypeAttached");
        res;
      });
      this.classBtn = { text: "Submit", disabled: false };
      this.classLoading = false;
    },
    async attachClassTypeFromAttendence(attendenceId) {
      const postData = {
        classtype_id: this.selectedClass,
        topic: this.selectedClass == 3 ? this.bridgeClassInfo : "",
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancedates/${attendenceId}/attach/classtypes`;
      await this.$axios.post(url, postData);
    },
    async getClassTypes() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/classtypes`;
      await this.$axios.get(url).then((res) => {
        const options = res.data;
        this.classTypesOptions = [{ value: null, text: "Regular" }];
        options.forEach(({ id, name }) => {
          this.$set(this.classTypesOptionsObj, id, name);
          this.classTypesOptions.push({ value: id, text: name });
        });
      });
    },
    async getClasstypeOfAttendence() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancedates/${this.attendanceId}?with_classtype=1`;
      await this.$axios.get(url).then((res) => {
        this.selectedClass = res.data.classtype_id;

        if (
          res.data.classtype != null &&
          res.data.classtype.code == "bridge_class"
        )
          this.bridgeClassInfo = res.data.topic;
        else this.bridgeClassInfo = "";
      });
    },
  },
};
</script>
