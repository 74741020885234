<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else>
      <div class="createLessonPlan mb-2">
        <b-form-checkbox size="sm" v-model="createTopic">
          <span class="text-info" style="font-size: 14px; font-weight: 600">Didn't find your topic on the list? Click
            here to add a new topic.</span>
        </b-form-checkbox>
      </div>
      <div class="createNewLessonPlan" v-if="createTopic">
        <CreateTopic :courseId="courseId" @topicCreated="topicCreated" />
      </div>
      <div class="exisingLessonPlansData" v-else>
        <div class="existing" v-if="existingPlans.length != 0">
          <b-overlay :show="loadPlan" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>Topic Name</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(plan, planIndex) in existingPlans" :key="planIndex">
                  <td class="text-align">{{ plan.lesson }}</td>
                  <td class="text-align text-center">
                    <span class="btn p-0 m-0 text-danger" @click="detachLesson(plan.id, planIndex)"><i
                        class="fas fa-trash ml-3"></i></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </div>

        <b-form @submit.prevent="attachLessons()" class="border-0">
          <!-- <b-button variant="info" @click="getAttachedLessonPlans()">GET</b-button> -->

          <b-form-group>
            <div style="text-align:right;">
              <b-button type="submit" v-if="lessonList.length != 0" variant="primary"
                :disabled="attachLessonBtn.disabled">{{ attachLessonBtn.text }}</b-button>
            </div>
          </b-form-group>
          <b-form-group>
            <b-list-group>
              <b-list-group-item class="text-danger" v-if="lessonList.length == 0">No topics created for this course
                yet!</b-list-group-item>
              <b-list-group-item v-else>
                <div v-if="lessonOptions.length == 0">
                  <p class="text-warning">All topics has been attcahed.</p>
                </div>
                <div v-else>
                  <b-form-checkbox-group :options="lessonOptions" v-model="selectedLessons" stacked>
                  </b-form-checkbox-group>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-form-group>

          <b-form-group>
            <div class="d-flex justify-content-between">
              <b-button type="submit" v-if="lessonList.length != 0" variant="primary"
                :disabled="attachLessonBtn.disabled">{{ attachLessonBtn.text }}</b-button>
            </div>
          </b-form-group>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CreateTopic from "./CreateTopic.vue";
export default {
  props: ["attenddenceId", "courseId"],
  created() {
    this.getLessonPlans();
  },
  components: {
    CreateTopic,
  },
  data() {
    return {
      fetchData: false,
      loadPlan: false,
      lessonPlans: [],
      lessons: [],
      lessonOptions: [],
      selectedLessons: [],
      lessonList: [],
      existingPlans: [],
      attachLessonBtn: {
        text: "Add",
        disabled: false,
      },
      createTopic: false,
    };
  },
  methods: {
    topicCreated() {
      this.getLessonPlans();
      this.createTopic = false;
    },
    async getLessonPlans() {
      this.fetchData = true;
      let lessonListUrl = this.$store.getters.getAPIKey.lessonList;
      const url = lessonListUrl.replace("course_id", this.courseId);

      axios
        .get(url)
        .then((response) => {
          this.lessonList = [];
          this.lessonOptions = [];
          this.lessonList = response.data;

          this.lessonList.forEach((lesson) => {
            this.lessonOptions.push({
              value: lesson.id,
              text: lesson.lesson,
            });
          });
          if (this.lessonList.length != 0) {
            this.getAttachedLessonPlans();
          } else {
            this.fetchData = false;
          }

          // console.log(response);
        })
        .catch(() => {
          this.fetchData = false;
          // console.log(error.response);
        });
    },
    attachLessons() {
      this.attachLessonBtn.text = "Please wait...";
      this.attachLessonBtn.disabled = true;
      let lessonPlan = this.$store.getters.getAPIKey.attachCourseLessonPlan;
      const url = lessonPlan.replace("attendancedateId", this.attenddenceId);
      const attachedLessons = {
        lessonplan_ids: this.selectedLessons,
      };

      axios
        .post(url, attachedLessons)
        .then(() => {
          this.getLessonPlans();
          this.getAttachedLessonPlans();
          this.clearResponse();
          // console.log(response);
        })
        .catch(() => {
          this.clearResponse();
          // console.log(error.response);
        });
    },
    async detachLesson(lessonId, lessonIndex) {
      this.loadPlan = true;
      let lessonPlan = this.$store.getters.getAPIKey.detachCourseLessonPlan;
      const url = lessonPlan.replace("attendancedateId", this.attenddenceId);
      const attachedLessons = {
        lessonplan_ids: [],
      };
      await attachedLessons.lessonplan_ids.push(lessonId);
      axios
        .post(url, attachedLessons)
        .then(() => {
          this.existingPlans.splice(lessonIndex, 1);
          this.getLessonPlans();
          this.getAttachedLessonPlans();
          this.loadPlan = false;
          // console.log(response);
        })
        .catch(() => {
          this.loadPlan = false;

          // console.log(error.response);
        });
    },
    getAttachedLessonPlans() {
      let lessonPlan = this.$store.getters.getAPIKey.getAttendenceDatesLessonPlans;
      const url = lessonPlan.replace("attendancedateId", this.attenddenceId);
      axios
        .get(url)
        .then((response) => {
          this.existingPlans = [];
          this.existingPlans = response.data;
          if (this.lessonList.length != 0) {
            this.existingPlans.forEach((existing) => {
              this.lessonOptions.forEach((lesson, lessonIndex) => {
                if (lesson.value == existing.id) {
                  this.lessonOptions.splice(lessonIndex, 1);
                }
              });
            });
          }
          this.fetchData = false;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },

    clearResponse() {
      setTimeout(() => {
        this.attachLessonBtn.text = "Add";
        this.attachLessonBtn.disabled = false;
      }, 3000);
    },
    cancel() {
      this.$bvModal.hide("lessonPlans");
      this.lessonList = [];
      this.lessonOptions = [];
      this.lessonPlans = [];
      this.lessons = [];
      this.selectedLessons = [];
      this.existingPlans = [];
    },
  },
};
</script>

<style >
.existingPlans .list-group-item {
  border-style: none;
}
</style>
