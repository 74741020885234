<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-container v-else>
      <b-row>
        <b-col md="12">
          <div class="lessonPlans">
            <br />

            <b-form @submit.prevent="prepareLessonPlans()">
              <h6 class="alert alert-success text-center" v-if="lessonCreated != ''">
                {{ lessonCreated }}
              </h6>
              <div v-for="(lessonP, lessonIndex) in lessonPlans" :key="lessonIndex">
                <b-form-group class="text-right" v-if="lessonIndex != 0">
                  <span class="text-danger btn p-0 m-0" @click="removeLesson(lessonIndex)"
                    ><i class="fas fa-minus-circle fa-2x"></i
                  ></span>
                </b-form-group>
                <b-form-group>
                  <div class="row">
                    <div class="col-md-2"></div>
                    <label for="" class="col-md-3">Lessons:</label>
                    <div class="col-md-7">
                      <b-form-textarea
                        placeholder="Enter Topic"
                        rows="3"
                        v-model="lessonP.lesson"
                        :class="{ 'is-invalid': responseErrors.lesson }"
                      ></b-form-textarea>
                      <span class="text-danger" v-if="responseErrors.lesson">{{
                        responseErrors.lesson[0]
                      }}</span>
                    </div>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="row">
                    <div class="col-md-2"></div>
                    <label for="" class="col-md-3">Planned Date:</label>
                    <div class="col-md-7">
                      <date-picker
                        v-model="lessonP.planned_dates"
                        :class="{ 'is-invalid': responseErrors.planned_date }"
                        format="DD/MM/YYYY"
                        placeholder="Select Date"
                      >
                        ></date-picker
                      >
                      <span class="text-danger" v-if="responseErrors.planned_dates">{{
                        responseErrors.planned_dates[0]
                      }}</span>
                    </div>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="row">
                    <div class="col-md-2"></div>
                    <label for="" class="col-md-3">No. of hours required</label>
                    <div class="col-md-7">
                      <b-form-input
                        placeholder="No. of hours required"
                        :class="{ 'is-invalid': responseErrors.required_hours }"
                        v-model="lessonP.required_hours"
                      ></b-form-input>

                      <span class="text-danger" v-if="responseErrors.required_hours">{{
                        responseErrors.required_hours[0]
                      }}</span>
                    </div>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="row">
                    <div class="col-md-2"></div>
                    <label for="" class="col-md-3">Select Course Outcome:</label>

                    <div class="col-md-7">
                      <multiselect
                        v-model="lessonP.course_outcomes"
                        :options="courseOutcomesOptions"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        placeholder="Select CO's"
                        label="text"
                        track-by="value"
                        :preselect-first="false"
                      >
                        <template slot="selection"
                          ><span
                            class="multiselect__single d-flex flex-wrap"
                            v-if="lessonP.course_outcomes"
                          >
                            <span
                              style="font-size: 12px; font-weight: 600"
                              v-for="(co, coIndex) in lessonP.course_outcomes"
                              :key="coIndex"
                            >
                              {{ co.text }}
                              <span
                                v-if="
                                  lessonP.course_outcomes.length > 1 &&
                                  lessonP.course_outcomes.length - 1 != coIndex
                                "
                                >,</span
                              >
                            </span>
                          </span></template
                        >
                      </multiselect>
                    </div>
                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="row">
                    <div class="col-md-2"></div>
                    <label for="" class="col-md-3">Select Module:</label>
                    <b-form-select
                      class="col-md-7"
                      :options="courseModulesOptions"
                      v-model="lessonP.course_module_id"
                    ></b-form-select>
                  </div>
                </b-form-group>
                <b-form-group>
                  <b-button
                    type="submit"
                    variant="primary"
                    size="sm"
                    :disabled="lessonBtn.disabled"
                    >{{ lessonBtn.text }}</b-button
                  >
                </b-form-group>
              </div>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// import mosment from "moment";
export default {
  props: ["courseId"],
  components: {
    DatePicker,
  },
  async created() {
    this.fetchData = true;
    await this.getCourseOutcomes();
    await this.getCourseModules();
  },

  data() {
    return {
      fetchData: false,
      lessonPlans: [
        {
          lesson: "",
          planned_dates: "",
          required_hours: "",
          course_id: this.courseId,
          course_outcomes: [],
          course_module_id: null,
        },
      ],
      lessonCreated: "",
      lessonBtn: {
        text: "Create Topic",
        disabled: false,
      },
      responseErrors: {},
      courseOutcomesOptions: [],
      courseModulesOptions: [],
    };
  },
  methods: {
    addMoreLesson() {
      this.lessonPlans.push({
        lesson: "",
        planned_dates: "",
        required_hours: "",
        course_id: this.courseId,
        outcome: "",
        module: "",
      });
    },
    removeLesson(removeLesson) {
      this.lessonPlans.splice(removeLesson, 1);
    },
    async prepareLessonPlans() {
      this.lessonBtn = {
        text: "Creating...",
        disabled: true,
      };
      // let topicStatus = { id: null, status: null };
      for (let lessonP in this.lessonPlans) {
        const co = [];
        await this.lessonPlans[lessonP].course_outcomes.forEach((outcome) => {
          co.push(outcome.value);
        });

        let lessonData = await {
          lesson: this.lessonPlans[lessonP].lesson,
          planned_dates: this.lessonPlans[lessonP].planned_dates,
          required_hours: this.lessonPlans[lessonP].required_hours,
          course_id: this.lessonPlans[lessonP].course_id,
          course_outcomes: co,
          course_module_id: this.lessonPlans[lessonP].course_module_id,
        };

        let lessonPlanCreation = await this.createLesson(lessonData);

        if (lessonPlanCreation.status) {
          this.lessonCreated = "Lesson plan created successfully.";
          this.$toast.success("Lesson Plan created successfully.", {
            position: "top",
            duration: 3000,
          });
          this.$emit("topicCreated");
        }
        if (!lessonPlanCreation) {
          this.clearResponse();
          return;
        }
      }
    },
    async createLesson(lessonData) {
      let status = {
        id: null,
        status: null,
      };
      const url = this.$store.getters.getAPIKey.lessonplans;

      await this.$axios
        .post(url, lessonData)
        .then((response) => {
          status.id = response.data.id;
          status.status = true;
          // console.log(response);
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          status.status = false;
          this.clearResponse();
          // console.log(error.response);
        });
      return status;
    },

    getCourseOutcomes() {
      let courseOutcomeUrl = this.$store.getters.getAPIKey.getCourseOutcomes;
      const url = courseOutcomeUrl.replace("course_id", this.courseId);
      axios
        .get(url)
        .then((response) => {
          this.courseOutcomesOptions = [
            { value: null, text: "Select Outcome", disabled: true },
          ];
          let outcomes = response.data;
          if (outcomes.length != 0) {
            outcomes.forEach((cOutcome) => {
              this.courseOutcomesOptions.push({
                value: cOutcome.id,
                text: cOutcome.code,
              });
            });
          }
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    getCourseModules() {
      let courseModuleUrl = this.$store.getters.getAPIKey.getCourseModules;
      const url = courseModuleUrl.replace("course_id", this.courseId);
      axios
        .get(url)
        .then((response) => {
          this.courseModulesOptions = [
            { value: null, text: "Select Module", disabled: true },
          ];
          let modules = response.data;
          if (modules.length != 0) {
            modules.forEach((cModule) => {
              this.courseModulesOptions.push({
                value: cModule.id,
                text: cModule.module,
              });
            });
          }
          this.fetchData = false;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    clearResponse() {
      this.lessonBtn.text = "Try Again";

      setTimeout(() => {
        if (this.lessonCreated != "") {
          this.lessonBtn.text = "Create Topic";
          this.lessonCreated = "";
          this.cancel();
        }
        this.lessonBtn.disabled = false;
        this.responseErrors = {};
      }, 3000);
    },
    cancel() {
      this.lessonPlans = [
        {
          lesson: "",
          planned_dates: "",
          required_hours: "",
          course_id: [],
          course_outcomes: null,
          course_module_id: null,
        },
      ];
      //   this.$emit("cancel");
    },
  },
};
</script>

<style >
.lessonPlans form label {
  font-size: 18px;
  font-weight: bold;
}
</style>
