<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="attendenceMarking" v-else>
      <b-container>
        <b-row>
          <b-col md="12">
            <h6 class="alert alert-danger text-center" v-if="
              attendenceErrors.attendance_dates &&
              attendenceErrors.attendance_dates.length != 0
            ">
              Attendance is already marked by
              <span v-for="(user, userIndex) in attendenceErrors.attendance_dates" :key="userIndex">
                {{
                    user.institution_user.first_name +
                    " " +
                    user.institution_user.last_name +
                    " on " +
                    formatTime(user.date_time) +
                    " & session: " +
                    user.session.name
                }}
                <span v-if="
                  attendenceErrors.attendance_dates.length > 1 &&
                  userIndex != attendenceErrors.attendance_dates.length - 1
                ">,
                </span>
              </span>
            </h6>
            <h6 class="alert alert-success text-center" v-if="responseErrors.attendenceCreated">
              {{ responseErrors.attendenceCreated[0] }}
            </h6>

            <h6 class="alert alert-warning text-center" v-if="
              responseErrors.attendance_date &&
              responseErrors.attendance_dates
            ">
              Attendance is already marked by

              <span v-for="(user, userIndex) in responseErrors.attendance_dates" :key="userIndex">
                {{ user.institution_user.first_name + " "
                }}{{ user.institution_user.last_name || ""
}}{{
    " on " +
    formatTime(user.date_time) +
    " & session: " +
    user.session.name
}}
                <span v-if="
                  responseErrors.attendance_dates.length > 1 &&
                  userIndex != responseErrors.attendance_dates.length - 1
                ">,
                </span>
              </span>
            </h6>

            <h6 class="alert alert-success text-center" v-if="studentsMarked != ''">
              {{ studentsMarked }}
            </h6>

            <!-- edit attendance yes or no  -->
            <div class="editOrNot" v-if="editAttendance">
              <div class="text-center">
                <div>
                  <h4 class="text-info text-center">Do you want to edit it?</h4>
                  <div class="d-flex justify-content-center">
                    <b-button variant="primary" size="md" @click="editMarkedAttendence(true)">Yes</b-button>
                    <b-button variant="danger" size="md" class="ml-3" @click="editMarkedAttendence(false)">No</b-button>
                  </div>
                </div>
              </div>
            </div>

            <!-- date course session form -->
            <b-form @submit.prevent="attendenceDate()" v-else>
              <b-form-group>
                <h5 class="text-center font-weight-bolder">
                  Instructor Attendance Marking
                </h5>
              </b-form-group>

              <b-form-group>
                <b-form-datepicker v-model="createAttendence.date_time" :disabled="getUsersBtn.disabled" :class="{
                  'is-invalid': validationErrors.attendance_dates,
                }"></b-form-datepicker>
                <span class="text-danger" v-if="validationErrors.attendance_dates">{{
                    validationErrors.attendance_dates[0]
                }}</span>
              </b-form-group>

              <b-form-group>
                <b-form-select v-model="createAttendence.course_id" :disabled="getUsersBtn.disabled"
                  :options="coursesOptions" :class="{ 'is-invalid': responseErrors.errors }"
                  @change="getRollNumbers(createAttendence.course_id.id)"></b-form-select>
                <span class="text-danger" v-if="responseErrors.errors">{{
                    responseErrors.errors
                }}</span>
              </b-form-group>
              <b-form-group label="Select Sessions">
                <b-form-checkbox-group size="lg" :options="sessionOptions" :disabled="getUsersBtn.disabled"
                  v-model="createAttendence.session_id"
                  :class="{ 'is-invalid': responseErrors.errors }"></b-form-checkbox-group>
                <span class="text-danger" v-if="responseErrors.errors">{{
                    responseErrors.errors
                }}</span>
              </b-form-group>
              <b-form-group v-if="!attendenceDates">
                <b-button type="submit" variant="primary" size="md" :disabled="
                  getUsersBtn.disabled ||
                  createAttendence.date_time == null ||
                  createAttendence.course_id == null ||
                  createAttendence.session_id.length == 0
                " block>{{ getUsersBtn.text }}</b-button>
              </b-form-group>
            </b-form>
            <b-form-group>
              <b-button v-if="attendenceDates" type="button" variant="danger" size="sm" @click="cancel()"
                block>Cancel</b-button>
            </b-form-group>
            <br />

            <!-- absentees sms atert model -->
            <b-modal id="checkMarkedStudents" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
              hide-header-close hide-footer centered size="lg" :title="
                'Confirm Attendance Marking for ' +
                studentAttendence.length +
                ' students'
              ">
              <div class="loading" v-if="attendenceLoading">
                <load-data></load-data>
              </div>

              <div v-else>
                <div class="d-flex flex-column">
                  <div style="text-align:center;">
                    <div v-if="notMarkedStudents.disabled.length">
                      <span style="font-size: 15px !important; color:blue">
                        {{ notMarkedStudents.disabled.length }}/{{
                            notMarkedStudents.disabled.length +
                            notMarkedStudents.marked.length +
                            notMarkedStudents.notmarked.length
                        }}</span>
                      <span> students are disabled</span>
                    </div>
                    <div v-if="notMarkedStudents.notmarked.length">
                      <span>You missed to mark </span>
                      <strong style="font-size: 25px !important; color:red">{{ notMarkedStudents.notmarked.length }}/
                        {{
                            notMarkedStudents.marked.length +
                            notMarkedStudents.notmarked.length
                        }}</strong>
                      <span> students</span>
                    </div>
                    <div v-if="
                      notMarkedStudents.notmarked.length > 1 &&
                      !courseGlobalgroupStudents.options.length
                    " class="blink_me">
                      <span @click="createCourseGlobalgroup"
                        style="color: #2929aa; text-decoration: underline; cursor: pointer;">Create group</span>
                      with these students, to remain the selection
                    </div>
                  </div>

                  <span style="font-size: 15px !important; ">Attendance summary of
                    {{ notMarkedStudents.marked.length }}/{{
                        notMarkedStudents.marked.length +
                        notMarkedStudents.notmarked.length
                    }}
                    students :</span>
                  <p v-for="(attendence, attendenceIndex) in attendenceTypes" :key="attendenceIndex"
                    class="font-weight-bolder text-info mb-2">
                    <span v-if="
                      totalMarkedAttendences[attendence.code] &&
                      totalMarkedAttendences[attendence.code].length
                    ">
                      {{ attendence.name }}:
                      {{ totalMarkedAttendences[attendence.code].length }}</span>
                  </p>
                  <div
                    v-b-popover.hover.top="attendance_alert.absentees_on_marking_to_student || attendance_alert.absentees_on_marking_to_parent ? 'The SMS Alert option is enabled by the admin, so faculties will not be able to disable it.' : ''">
                    <b-form-checkbox :disabled="attendance_alert.absentees_on_marking_to_student" v-model="send_sms"
                      class="mb-2"> Send absenteeism SMS alert to
                      students</b-form-checkbox>
                    <b-form-checkbox :disabled="attendance_alert.absentees_on_marking_to_parent"
                      v-model="send_sms_to_guardians" class="mb-2">Send absenteeism SMS alert to
                      guardians.</b-form-checkbox>
                  </div>

                  <p style="font-size: 14px; font-weight: 600" :class="smsCount < 1000 ? 'text-danger' : 'text-dark'">
                    Balance SMS Count:
                    <span>{{ smsCount }}</span>
                  </p>
                </div>
                <div class="btns d-flex justify-content-between">
                  <b-button size="sm" variant="primary" :disabled="markBtn.disabled"
                    @click="attendenceMark()">Submit</b-button>
                  <b-button size="sm" variant="danger" @click="$bvModal.hide('checkMarkedStudents')">Cancel</b-button>
                </div>
              </div>

              <br /><br />
              <div class="loading" v-if="!attendanceTypeLimits.view">
                <load-data></load-data>
              </div>
              <div v-if="
                attendanceTypeLimits.view &&
                Object.keys(attendanceTypeLimits.data).length > 0
              ">
                <h3 style="font-size: 15px !important; ">
                  Student Attendance percantages
                </h3>
                <table class="table  table-striped table-bordered">
                  <tr>
                    <th>Student</th>
                    <th>attendance</th>
                    <th>% by total count, Limit</th>
                    <th>
                      % by planned({{ attendanceTypeLimits.planned_houres }}),
                      limit
                    </th>
                  </tr>
                  <tr v-for="(data, reindex) in attendanceTypeLimits.data" :key="reindex" :style="
                    data.percentage > data.limit ||
                      (attendanceTypeLimits.planned_houres * data.percentage) /
                      100 >
                      data.limit
                      ? 'color: red;'
                      : ''
                  ">
                    <td>{{ data.name }}</td>
                    <td>
                      {{ data.attTypeName }}- {{ data.count }}/
                      {{ data.max_count }}
                    </td>
                    <td>
                      <span v-if="data.percentage > data.limit">{{ data.percentage }} > {{ data.limit }}</span>
                      <span v-else>{{ data.percentage }}</span>
                    </td>
                    <td>
                      <span v-if="
                        (attendanceTypeLimits.planned_houres *
                          data.percentage) /
                        100 >
                        data.limit
                      ">{{
    Math.round(
      (attendanceTypeLimits.planned_houres *
        data.percentage) /
      10
    ) / 10
}}
                        > {{ data.limit }}</span>
                      <span v-else>{{
                          Math.round(
                            (attendanceTypeLimits.planned_houres *
                              data.percentage) /
                            10
                          ) / 10
                      }}</span>
                    </td>
                  </tr>
                </table>
              </div>
            </b-modal>

            <!-- create globalgroup for course model -->
            <b-modal id="createCourseglobalgroupModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
              hide-footer centered size="lg" lazy v-if="createAttendence.course_id && createAttendence.course_id.id">
              <coursestudentsgrouping-dash lazy :courseId="createAttendence.course_id.id"
                :create_with_user_ids_array="notMarkedStudents.marked" :create_with_summary="'For marking attendance'"
                :create_with_name="'Attendance group 1'"></coursestudentsgrouping-dash>
            </b-modal>

            <!-- attach lesson model -->
            <b-modal id="attachLessonPlanFromAttendence" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
              hide-header-close hide-footer centered size="lg" title="Attach Lesson Plans & Delivery Type">
              <AttachLessonFromAttendence @closeLessonPlan="closeLessonPlan" :classtype_id="
                responseErrors.attendance_date &&
                  responseErrors.attendance_date.classtype_id
                  ? responseErrors.attendance_date.classtype_id
                  : undefined
              " :attenddenceId="existingAttendenceId" :courseId="
  createAttendence.course_id != null &&
    createAttendence.course_id.id
    ? createAttendence.course_id.id
    : ''
" />
            </b-modal>

            <!-- attach lesson model -->
            <b-modal id="attachLessionWithMultipleSessions" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
              hide-header-close hide-footer centered size="lg" title="Attach Lesson Plans & Delivery Type">
              <AttachLessonsWithMultipleSessions :courseId="
                createAttendence.course_id != null &&
                  createAttendence.course_id.id
                  ? createAttendence.course_id.id
                  : ''
              " :multipleAttendenceData="multipleAttendenceData" :allSessionDetails="allSessionDetails"
                @closeMultiSession="closeMultiSession" />
            </b-modal>

            <!-- mark attendance model -->
            <b-modal id="markAttendenceForStudents" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
              hide-footer hide-header-close :title="
                'Mark Attendance For ' + checkCourseName + checkSessionNames
              " modal-class="markAttendence">
              <!-- <p>CLASS: {{responseErrors.attendance_date ? responseErrors.attendance_date : ''}}</p> -->
              <div class="loading" v-if="fetchData">
                <load-data></load-data>
              </div>
              <div v-else>
                <div class="d-flex mb-2 justify-content-end" style="position: sticky; top: 0; z-index: 2">
                  <b-button size="md" variant="success" :disabled="markBtn.disabled" @click="showMarkedTotals()"
                    v-if="studentAttendence.length !== 0">{{ markBtn.text }}</b-button>
                  <b-button variant="danger" :disabled="markBtn.disabled" class="ml-3" size="md"
                    @click="cancelMarkAttendence()">Cancel</b-button>
                </div>
                <div class="markStudents border border-info p-3 rounded mb-3">
                  <b-form-checkbox v-model="rollnumberPrefix.enable" size="md">Roll number prefixes
                    <span class="d-inline-block" tabindex="0" data-toggle="tooltip"
                      title="If roll numbers are like, ECU18A 01, ECU18A 02, ECU18A 03... then enter prefix 'ECU18A ' , then you can enter roll number like 01, 02, 03...">
                      <i style="font-size: 18px !important; color: #3c6f39fc;" class="fas fa-info-circle  fa-xm"></i>
                    </span>
                  </b-form-checkbox>
                  <div class="limitAttendence w-50 d-flex" v-if="rollnumberPrefix.enable">
                    <b-form-input placeholder="KTUTST" style="width: 100px"
                      v-model="rollnumberPrefix.text"></b-form-input>
                  </div>

                  <div class="pt-2 mt-2">
                    <b-form-group v-if="
                      courseGlobalgroupStudents.enable &&
                      !courseGlobalgroupStudents.fetchData
                    " label="Select Course students group to limit:">
                      <b-form-select :options="courseGlobalgroupStudents.options"
                        v-model="courseGlobalgroupStudents.selectedGroupId"
                        @change="updateAttenteesWithCoursegroup"></b-form-select>
                    </b-form-group>
                    <h4 v-if="courseGlobalgroupStudents.fetchData" style="color: red;">
                      Updating student group, Please wait...
                    </h4>
                  </div>

                  <div v-for="(attendence, attendenceIndex) in attendenceTypes" :key="attendenceIndex">
                    <b-form-tags v-if="
                      attendence.code != 'P' &&
                      (attendence.code == 'AB' ||
                        attndanceMarkingMode == 'edit')
                    " v-model="markStudents[attendence.name]" :tag-validator="validateStudentsRollNo"
                      :invalidTagText="rollNoErrorText" :placeholder="
                        `Enter ${attendence.name} Students Roll No.`
                      " tag-variant="primary" tag-pills class="mb-2 mt-2" no-outer-focus>
                      <template>
                        <b-input-group>
                          <b-form-input autocomplete="off" v-model="markStudents[attendence.name + 'Text']"
                            :placeholder="
                              `Enter ${attendence.name} Roll No's seprated by commas / Use roll number range. Example: 1-10 `
                            " @keydown.,="
  addingRollNumber(
    markStudents[attendence.name + 'Text'],
    attendence.name,
    attendence.code,
    attendence.id
  )
" @keypress="avoidComma"></b-form-input>
                        </b-input-group>

                        <div v-if="
                          markStudents[attendence.name] &&
                          markStudents[attendence.name].length > 0
                        ">
                          <b-form-tag v-for="(tag, tagIndex) in markStudents[
                            attendence.name
                          ]" :key="tagIndex" @remove="
  removeRollNumber(tag, tagIndex, attendence.name)
" class="mr-1" variant="primary">{{ tag }}</b-form-tag>
                        </div>

                        <b-form-text v-else>
                          No roll numbers added yet!
                        </b-form-text>
                      </template>
                    </b-form-tags>
                    <div v-if="attendence.code == 'P'">
                      <b-form-checkbox @change="
                        markLeftOversAsPresent(attendence.code, attendence.id)
                      " class="mb-2" v-model="markStudents.Present">Mark all other students as
                        Present</b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="attendenceStatus border border-info p-3 rounded" v-if="attendenceDates">
                  <div class="text-center">
                    <b-button-group id="attendenceMarking">
                      <span v-for="(attendence, attendenceIndex) in attendenceTypes" :key="attendenceIndex">
                        <b-button v-if="
                          attndanceMarkingMode == 'edit' ||
                          attendence.code == 'P' ||
                          attendence.code == 'AB'
                        " :variant="
  attendence.code == 'P' || 'AB'
    ? attendence.color
    : ''
" @click="
  markAttendence(
    attendence.code,
    true,
    0,
    attendence.id,
    attendence.name
  )
" :style="
  attendence.code != 'P' && attendence.code != 'AB'
    ? `backgrounColor: ${attendence.color}; border: 1px solid #1ea2eb; border-radius: 10px`
    : ''
" :class="
  attendenceIndex == 0
    ? 'text-center'
    : 'text-center ml-2'
">{{ attendence.code }} <span>All</span></b-button>
                      </span>

                      <b-button variant="light" @click="clearAll()" class="text-center ml-2 border-border-info">Clear
                        <span>All</span></b-button>
                      <p class="" v-if="attndanceMarkingMode == 'create'" @click="attndanceMarkingMode = 'edit'"
                        style="color: #2929aa; text-decoration: underline; cursor: pointer;">
                        Enable all options..
                      </p>
                      <p class="" v-if="attndanceMarkingMode == 'edit'" @click="attndanceMarkingMode = 'create'"
                        style="color: #2929aa; text-decoration: underline; cursor: pointer;">
                        Minimal View
                      </p>
                    </b-button-group>
                  </div>

                  <br />
                  <b-list-group>
                    <b-list-group-item v-if="studentAttendence.length == 0">
                      <p class="text-danger">
                        No Students Found!
                      </p>
                    </b-list-group-item>
                    <b-list-group-item v-else class="border-0">
                      <div class="userProfile p-0 w-100 d-flex flex-wrap">
                        <div v-for="(student, studentIndex) in studentAttendence" :key="studentIndex" :style="
                          $screenSize > 600
                            ? { width: '130px' }
                            : { width: '90px' }
                        " class="text-center">
                          <b-avatar :style="
                            student.disabled
                              ? 'opacity: 0.15 !important;'
                              : ''
                          " :id="'courseUser' + studentIndex" variant="primary" class="'btn m-0 p-0'"
                            :disabled="student.disabled ? true : false"
                            src="https://icons-for-free.com/iconfiles/png/512/avatar+circle+male+profile+user+icon-1320196710301016992.png"
                            size="3rem"><template #badge v-if="student.status != ''">
                              {{ student.status == "" ? "N" : student.status }}
                            </template>
                          </b-avatar>
                          <p class="text-center" style="font-size: 12px; font-family: Roboto Regular">
                            {{ student.name }} <br />
                            <span class="bg-info text-white p-1" style="font-size: 12px">Roll No. {{ student.rollNumber
                            }}</span>
                          </p>

                          <br />

                          <b-popover :target="'courseUser' + studentIndex" triggers="hover" placement="top">
                            <div class="p-2 bg-white d-flex">
                              <span v-for="(attendence,
                              attendenceIndex) in attendenceTypes" :key="attendenceIndex">
                                <b-button v-if="
                                  attndanceMarkingMode == 'edit' ||
                                  attendence.code == 'P' ||
                                  attendence.code == 'AB'
                                " pill variant="info" class="ml-2" @click="
  markAttendence(
    attendence.code,
    false,
    studentIndex,
    attendence.id,
    attendence.name
  )
">
                                  {{ attendence.code }}
                                </b-button>
                              </span>
                            </div>
                          </b-popover>
                        </div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>
            </b-modal>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import AttachLessonFromAttendence from "./AttachLessonFromAttendence.vue";
import AttachLessonsWithMultipleSessions from "./AttachLessonsWithMultipleSessions.vue";
import CoursestudentsgroupingDash from "../Course/students_grouping/studentsgrouping.vue";

moment;
export default {
  props: [
    "attendenceInfo",
    "attendenceDateFromListing",
    "attendenceListingEdit",
  ],
  async created() {
    this.fetchData = true;
    this.smsCount = this.$store.getters.getSmsCount.smsBalance;
    await window.addEventListener("resize", this.handleResize);
    this.createAttendence.date_time = new Date();
    await this.handleResize();
    await this.getCourseDetails();
    await this.getSessions();
    await this.getAttendenceType();

    await this.attendenceDateFromListing;

    if (this.attendenceListingEdit) {
      this.createAttendence = await this.attendenceDateFromListing;
      await this.getRollNumbers(this.createAttendence.course_id.id);
      await this.editAttendenceFromListing();
    }

    this.$toast.success(
      "<a href='https://play.google.com/store/apps/details?id=in.chipsnbytes.ezygo.ezygo_app1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img height='50' style='margin: -19px;'  alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>  ",
      {
        // position: "top",
        duration: 7000,
      }
    );

    // this.getAttendanceAlertSettings();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  components: {
    AttachLessonFromAttendence,
    AttachLessonsWithMultipleSessions,
    CoursestudentsgroupingDash: CoursestudentsgroupingDash,
  },
  data() {
    return {
      fetchData: false,
      smsCount: null,
      attendenceLoading: false,
      markStudents: {
        Present: false,
        rollNumbersStatus: {},
      },
      attendenceListing: false,
      rollNoErrorText: "",
      screenWidth: 0,
      createAttendence: {
        session_id: [],
        usersubgroup_id: "",
        course_id: null,
        date_time: null,
      },
      existingAttendenceId: "",
      allAttendenceIds: [],
      editAttendance: false,
      attendenceTypes: [],
      coursesOptions: [{ value: null, text: "Select Course", disabled: true }],
      sessionOptions: [],
      courses: [],
      courseUsers: {},
      selectedUsers: {},
      studentAttendence: [],
      getUsersBtn: {
        text: "GO",
        disabled: false,
      },
      markBtn: {
        text: "Proceed",
        disabled: false,
      },
      attendenceIds: [],
      attendenceDates: false,
      responseErrors: {},
      validationErrors: {},
      studentsMarked: "",
      existingMarkedAttendence: [],
      studentsRollNumber: [],
      responseWarning: {},
      attendenceErrors: {},
      rollNoAttendenceData: {},
      totalMarkedAttendences: {},
      rollNumbersToLimit: {},
      allSessionDetails: {},
      multipleAttendenceData: [],
      rollNumberRange: {},
      send_sms: false,
      send_sms_to_guardians: false,
      rollnumberPrefix: { enable: false, text: "" },
      courseGlobalgroupStudents: {
        enable: false,
        options: [],
        selectedGroupId: null,
        students: [],
        fetchData: false,
      },
      attndanceMarkingMode: "edit", // [edit, create]- edit has full options, create has limitted user frindly
      notMarkedStudents: {
        notmarked: [],
        disabled: [],
        marked: [],
      },

      attendanceTypeLimits: {
        view: false,
        message: "",
        data: {},
        planned_houres: null,
      },
      attendance_alert: {
        absentees_on_marking_to_student: false,
        absentees_on_marking_to_parent: false,
      },
    };
  },
  computed: {
    checkCourseName() {
      if (
        this.createAttendence.course_id != null &&
        this.createAttendence.course_id.courseName
      ) {
        return (
          this.createAttendence.course_id.courseName +
          " on " +
          moment(this.createAttendence.date_time).format("DD/MM/YYYY") +
          " & "
        );
      } else {
        return "";
      }
    },

    checkSessionNames() {
      if (this.createAttendence.session_id.length != 0) {
        let sessionsNamesArray = [];
        let sessionNamesString;
        this.createAttendence.session_id.forEach((session) => {
          sessionsNamesArray.push(this.allSessionDetails[session].name);
        });
        sessionNamesString = sessionsNamesArray.join(", ");

        return sessionsNamesArray.length > 1
          ? " sessions "
          : " session " + sessionNamesString;
      } else {
        return "";
      }
    },
  },
  methods: {
    getAttendanceAlertSettings() {
      const settingKey = "attendance_alert";
      let mainUrl = this.$store.getters.getAPIKey.institutionSettings;
      const url = mainUrl.replace("setting_key", settingKey);
      axios
        .get(url)
        .then((response) => {
          let setting = response.data;
          if (setting) {
            this.attendance_alert.absentees_on_marking_to_student = setting.absentees_on_marking_to_student;
            this.attendance_alert.absentees_on_marking_to_parent = setting.absentees_on_marking_to_parent;
          }
        });
    },
    // get course global groupstudents and disable other students
    async updateAttenteesWithCoursegroup() {
      this.courseGlobalgroupStudents.fetchData = true;
      await this.clearAll();
      this.courseGlobalgroupStudents.students = [];
      await this.studentAttendence.forEach((student) => {
        student.disabled = false;
      });
      // refresh defaukts disablings
      await this.existingMarkedAttendence.forEach((marked) => {
        this.studentAttendence.forEach((student) => {
          if (
            student.institution_user_id == marked.attendee_id &&
            marked.attendance_type_id != ""
          ) {
            this.attendenceTypes.forEach((attendence) => {
              if (attendence.id == marked.attendance_type_id) {
                student.status = attendence.code;
                student.attendenceId = attendence.id;
                if (this.existingAttendenceId != marked.attendance_date_id) {
                  student.disabled = true;
                }
              }
            });
          }
        });
      });

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/globalgroups/${this.courseGlobalgroupStudents.selectedGroupId}/globalgroupusers`;
      if (this.courseGlobalgroupStudents.selectedGroupId)
        await axios
          .get(url)
          .then((response) => {
            this.courseGlobalgroupStudents.students = response.data;
            // console.log(response);
          })
          .catch(() => {
            this.$toast.error("Failed to get List", {
              position: "top",
              duration: 3000,
            });
          });

      if (this.courseGlobalgroupStudents.students.length) {
        // now disable by group
        let groupStudentIds = [];
        await this.courseGlobalgroupStudents.students.forEach((groupStud) => {
          groupStudentIds.push(groupStud.id);
        });

        await this.studentAttendence.forEach((student) => {
          if (!groupStudentIds.includes(student.institution_user_id)) {
            student.disabled = true;
          }
        });

        // this.courseGlobalgroupStudents.enable = false;
      }

      this.courseGlobalgroupStudents.fetchData = false;
      this.$forceUpdate();
    },
    //get course global groups for filter and disable students
    getCourseStudentGlobalgroups(course_id) {
      this.courseGlobalgroupStudents.enable = false;
      this.courseGlobalgroupStudents.options = [];
      this.courseGlobalgroupStudents.selectedGroupId = null;
      this.courseGlobalgroupStudents.students = [];

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/globalgroups?model_type=Course&model_id=${course_id}`;
      axios.get(url).then((response) => {
        let groups = response.data;
        if (groups.length) {
          this.courseGlobalgroupStudents.options.push({
            text: "Choose student group",
            value: null,
          });
          groups.forEach((group) => {
            this.courseGlobalgroupStudents.options.push({
              text: group.name,
              value: group.id,
            });
          });
          this.courseGlobalgroupStudents.enable = true;
        }
      });
    },
    formatTime(date) {
      if (date != undefined || date != null || date != 0) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return 0;
      }
    },
    avoidComma($event) {
      // console.log("avoidComma");
      if (
        !/[a-zA-Z0-9 ]/.test($event.key) &&
        $event.key !== "." &&
        $event.key !== "-"
      )
        return $event.preventDefault();
    },

    async editAttendenceFromListing() {
      this.fetchData = true;
      await this.attendenceDate();
      this.fetchData = false;
    },
    async showMarkedTotals() {
      this.attendenceLoading = true;
      this.$bvModal.show("checkMarkedStudents");
      await this.attendenceTypes.forEach((attendence) => {
        this.$set(this.totalMarkedAttendences, attendence.code, []);
      });

      this.notMarkedStudents.notmarked = [];
      this.notMarkedStudents.disabled = [];
      this.notMarkedStudents.marked = [];
      await this.studentAttendence.forEach((student) => {
        if (student.disabled) {
          this.notMarkedStudents.disabled.push(student.institution_user_id);
        } else {
          if (this.totalMarkedAttendences[student.status]) {
            this.totalMarkedAttendences[student.status].push(student);
            this.notMarkedStudents.marked.push(student.institution_user_id);
          } else {
            this.notMarkedStudents.notmarked.push(student.institution_user_id);
          }
        }
      });
      this.attendenceLoading = false;
      this.checkAttendanceTypeLimits();
      if (this.attendance_alert.absentees_on_marking_to_parent)
        this.send_sms_to_guardians = true;
      if (this.attendance_alert.absentees_on_marking_to_student)
        this.send_sms = true;
    },

    async checkAttendanceTypeLimits() {
      this.attendanceTypeLimits.data = [];
      this.attendanceTypeLimits.view = false;
      let postData = [];

      if (this.existingAttendenceId != "") {
        await this.studentAttendence.forEach((student) => {
          if (
            student.attendenceId != null &&
            student.status !== "" &&
            !student.disabled
          ) {
            postData.push({
              attendee_id: student.institution_user_id,
              attendance_type_id: student.attendenceId,
              attendance_date_id: this.existingAttendenceId,
              remarks: "Good",
            });
          }
        });
      }
      if (this.existingAttendenceId == "") {
        await this.attendenceIds.forEach((ids) => {
          this.studentAttendence.forEach((student) => {
            if (
              student.attendenceId != null &&
              student.status !== "" &&
              !student.disabled
            ) {
              postData.push({
                attendee_id: student.institution_user_id,
                attendance_type_id: student.attendenceId,
                attendance_date_id: ids,
                remarks: "Good",
              });
            }
          });
        });
      }

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/markattendance/atttypelimit/check`;
      axios
        .post(url, { attendances: postData })
        .then((response) => {
          this.attendanceTypeLimits.data = response.data.data;
          this.attendanceTypeLimits.planned_houres =
            response.data.planned_houres;
          if (response.data.message != "")
            this.$toast.warning(response.data.message, {
              position: "top",
              duration: 3000,
            });
          this.attendanceTypeLimits.message = response.data.message;
          this.attendanceTypeLimits.view = true;
          // $this.$forceUpdate();
        })
        .catch(() => {
          this.attendanceTypeLimits.view = true;
          this.$toast.error("Error: Failed to check attendance type limits", {
            position: "top",
            duration: 3000,
          });
        });
    },

    closeLessonPlan() {
      this.$bvModal.hide("attachLessonPlanFromAttendence");
      this.clearResponse();
    },
    closeMultiSession() {
      this.$bvModal.hide("attachLessionWithMultipleSessions");
      this.clearResponse();
    },

    prepareRollNoData(attendenceId, attendenceCode) {
      this.rollNoAttendenceData.id = attendenceId;
      this.rollNoAttendenceData.code = attendenceCode;
    },
    validateStudentsRollNo(rollNo) {
      if (isNaN(rollNo)) {
        this.rollNoErrorText = "Only Numbers are allowed!";
        return false;
      }

      if (
        this.markStudents.rollNumbersStatus[rollNo] &&
        this.markStudents.rollNumbersStatus[rollNo].status == 1
      ) {
        this.rollNoErrorText = "This Roll Number is already assigned!";
        return false;
      }
      return true;
    },
    async addingRollNumber(
      rollNo,
      rollNumberArray,
      attendenceCode,
      attendenceId
    ) {
      // console.log("addingRollNumber");
      let rollNumberRange = this.markStudents[rollNumberArray + "Text"];
      this.rollNumberRange = {};

      if (
        rollNumberRange.match(/([0-9])+([-])+([0-9])/g) &&
        !this.rollnumberPrefix.enable
      ) {
        let getRollNumbberRange = await rollNumberRange.split("-");
        this.markStudents[rollNumberArray + "Text"] = "";
        return this.addRollNumberFromRange(
          Number(getRollNumbberRange[0]),
          Number(getRollNumbberRange[1]),
          rollNumberArray,
          attendenceCode,
          attendenceId
        );
      }

      if (!this.markStudents[rollNumberArray]) {
        this.markStudents[rollNumberArray] = [];
      }

      // add prefrix to roll number of enabled
      if (this.rollnumberPrefix.enable)
        rollNo = this.rollnumberPrefix.text + rollNo;
      // console.log("rollNo : " + rollNo);
      if (
        (this.markStudents.rollNumbersStatus[rollNo] &&
          this.markStudents.rollNumbersStatus[rollNo].status == null) ||
        (this.markStudents.rollNumbersStatus[rollNo] &&
          this.markStudents.rollNumbersStatus[rollNo].status == null &&
          this.rollNumbersToLimit[rollNo] &&
          this.rollNumbersToLimit[rollNo] == rollNo)
      ) {
        this.markStudents[rollNumberArray].push(rollNo);
        this.markStudents.rollNumbersStatus[rollNo].status = await 1;
        this.markStudents.rollNumbersStatus[
          rollNo
        ].currentType = await rollNumberArray;
        this.markStudents[rollNumberArray + "Text"].replace(/,/g, "");
        this.markStudents[rollNumberArray + "Text"] = "";
        // console.log("marking rollNo : " + rollNo);
        await this.markAttendence(
          attendenceCode,
          false,
          this.markStudents.rollNumbersStatus[rollNo].studentIndex,
          attendenceId,
          rollNumberArray
        );
      }
    },
    async addRollNumberFromRange(
      startingRollNo,
      EndingRollNumber,
      rollNumberArray,
      attendenceCode,
      attendenceId
    ) {
      let startRollNumber = Number(startingRollNo);
      let endRollNumber = Number(EndingRollNumber);
      if (startRollNumber > endRollNumber || endRollNumber < startRollNumber) {
        return this.$toast.error("Please check the roll number range", {
          position: "top",
          duration: 3000,
        });
      }
      for (
        startRollNumber;
        startRollNumber <= endRollNumber;
        startRollNumber++
      ) {
        if (
          this.markStudents.rollNumbersStatus[startRollNumber] &&
          this.markStudents.rollNumbersStatus[startRollNumber].status == null
        ) {
          this.markStudents[rollNumberArray].push(startRollNumber);
          this.markStudents.rollNumbersStatus[startRollNumber].status = await 1;
          this.markStudents.rollNumbersStatus[
            startRollNumber
          ].currentType = await rollNumberArray;
          await this.markAttendence(
            attendenceCode,
            false,
            this.markStudents.rollNumbersStatus[startRollNumber].studentIndex,
            attendenceId,
            rollNumberArray
          );
        }
      }
      await this.rollNumberRange;
    },
    async removeRollNumber(rollNo, rollNumberIndex, rollNumberArray) {
      if (
        this.markStudents.rollNumbersStatus[rollNo] &&
        this.markStudents.rollNumbersStatus[rollNo].status != null
      ) {
        this.markStudents[rollNumberArray].splice(rollNumberIndex, 1);
        this.markStudents.rollNumbersStatus[rollNo].status = null;
        this.studentAttendence[
          this.markStudents.rollNumbersStatus[rollNo].studentIndex
        ].status = "";
        this.studentAttendence[
          this.markStudents.rollNumbersStatus[rollNo].studentIndex
        ].attendenceId = null;
        if (this.markStudents.Present) {
          let presentData = await this.attendenceTypes.filter((attendence) => {
            return attendence.code == "P";
          });
          this.markLeftOversAsPresent(presentData[0].code, presentData[0].id);
        }
        this.$forceUpdate();
      }
    },
    cancelMarkAttendence() {
      this.$bvModal.hide("markAttendenceForStudents");
      this.cancel();
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
      this.$forceUpdate();
    },
    async markAttendenceFromMySchedule() {
      this.createAttendence.session_id = await [];
      this.createAttendence.date_time = await "";
      this.createAttendence.course_id = await "";
      await this.createAttendence.session_id.push(
        this.attendenceInfo.sessionId
      );
      this.createAttendence.date_time = await this.attendenceInfo.date;
      this.createAttendence.course_id = await this.attendenceInfo.courseId;
      await this.getRollNumbers(this.createAttendence.course_id.id);
      await this.attendenceDate();
      this.$forceUpdate();
    },
    async attendenceDate() {
      this.attendenceErrors = {};
      this.getUsersBtn.text = "Please Wait...";
      this.getUsersBtn.disabled = true;
      const attendenceData = {
        attendance_dates: [],
      };
      await this.createAttendence.session_id.forEach((session) => {
        attendenceData.attendance_dates.push({
          session_id: session,
          usersubgroup_id: this.createAttendence.usersubgroup_id,
          course_id: this.createAttendence.course_id.id,
          date_time: new Date(
            moment(this.createAttendence.date_time).format("YYYY-MM-DD")
          ),
        });
      });

      //-------------------------------
      const url = this.$store.getters.getAPIKey.attendenceDates;
      axios
        .post(url, attendenceData)
        .then((response) => {
          this.existingAttendenceId = "";
          if (response.status == 201) {
            this.attndanceMarkingMode = "create";
            this.responseErrors = {
              ["attendenceCreated"]: [
                "The attendence date is created successfully!",
              ],
            };

            this.attendenceDates = true;
            this.attendenceIds = [];
            this.allAttendenceIds = response.data;
            this.allAttendenceIds.forEach((attendence) => {
              this.attendenceIds.push(attendence.id);
            });
            this.multipleAttendenceData = response.data;
            if (this.multipleAttendenceData.length == 1) {
              this.existingAttendenceId = this.multipleAttendenceData[0].id;
            } else {
              this.existingAttendenceId = "";
            }
            this.attendenceDateCreated();
            // this.clearResponse();
          }

          if (response.status == 200) {
            this.responseErrors = {
              ["message"]: [response.data.message],
              ["attendance_date"]: response.data.attendance_date,
              ["attendance_dates"]: response.data.attendance_dates,
            };
            const dateTime = this.responseErrors.attendance_date.date_time;
            this.responseErrors.attendance_date.date_time = moment(
              dateTime
            ).format("DD/MM/YYYY");
            this.existingAttendenceId = this.responseErrors.attendance_date.id;
            this.editAttendance = true;
          }

          // after hsow attendance mark model
          // call global group and show if there a group available for the course

          this.getCourseStudentGlobalgroups(this.createAttendence.course_id.id);
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.validationErrors = error.response.data.errors;
            setTimeout(() => {
              (this.getUsersBtn.text = "Go"),
                (this.getUsersBtn.disabled = false);
              this.validationErrors = {};
            }, 3000);
            this.clearResponse();
          } else {
            this.attendenceErrors = {
              ["error"]: "Conflict",
              ["message"]: [error.response.data.message],
              ["attendance_date"]: error.response.data.attendance_date,
              ["attendance_dates"]: error.response.data.attendance_dates,
            };
            this.clearResponse();
          }

          this.$forceUpdate();
        });
    },
    async attendenceDateCreated() {
      await this.sortedArray();
      this.getAttendanceAlertSettings();
      // if (this.existingAttendenceId != "") {
      //   await this.$bvModal.show("markAttendenceForStudents");
      // } else {
      //   await this.$bvModal.show("attachLessionWithMultipleSessions");
      // }
    },
    editMarkedAttendence(status) {
      if (status) {
        this.attendenceMarkList();
        this.attendenceDates = true;
        this.editAttendance = false;
      }
      if (!status) {
        this.clearResponse();
      }
    },
    async attendenceMark() {
      this.$bvModal.hide("checkMarkedStudents");
      this.markBtn.text = "Processing...";
      this.markBtn.disabled = true;
      const markData = {
        attendances: [],
        send_sms: this.send_sms,
        send_sms_to_guardians: this.send_sms_to_guardians,
      };
      if (this.existingAttendenceId != "") {
        this.studentAttendence.forEach((student) => {
          if (
            student.attendenceId != null &&
            student.status !== "" &&
            !student.disabled
          ) {
            markData.attendances.push({
              attendee_id: student.institution_user_id,
              attendance_type_id: student.attendenceId,
              attendance_date_id: this.existingAttendenceId,
              remarks: "Good",
            });
          }
        });
      }
      if (this.existingAttendenceId == "") {
        await this.attendenceIds.forEach((ids) => {
          this.studentAttendence.forEach((student) => {
            if (
              student.attendenceId != null &&
              student.status !== "" &&
              !student.disabled
            ) {
              markData.attendances.push({
                attendee_id: student.institution_user_id,
                attendance_type_id: student.attendenceId,
                attendance_date_id: ids,
                remarks: "Good",
              });
            }
          });
        });
      }

      const url = this.$store.getters.getAPIKey.attendences;
      axios
        .post(url, markData)
        .then(() => {
          this.studentsMarked = "All selected students marked successfully.";
          this.$toast.success("All selected students marked successfully.", {
            position: "top",
            duration: 3000,
          });

          if (this.existingAttendenceId != "") {
            this.$bvModal.show("attachLessonPlanFromAttendence");
          } else {
            this.$bvModal.show("attachLessionWithMultipleSessions");
          }
        })
        .catch((error) => {
          this.attendenceErrors = {};
          this.attendenceErrors = error.response.data;
          setTimeout(() => {
            this.attendenceErrors = {};
            this.cancel();
          }, 3000);
        });
    },
    async attendenceMarkList() {
      // this.fetchData = true;

      let addendenceUrl = this.$store.getters.getAPIKey.attendencesList;
      const url = addendenceUrl.replace(
        "attendance_id",
        this.existingAttendenceId
      );
      await this.createAttendence.session_id.forEach((session) => {
        if (session == this.responseErrors.attendance_date.session.id) {
          this.createAttendence.session_id = [
            this.responseErrors.attendance_date.session.id,
          ];
        }
      });
      await axios
        .get(url)
        .then((response) => {
          this.existingMarkedAttendence = response.data;

          this.existingMarkedAttendence.forEach((marked) => {
            this.studentAttendence.forEach((student) => {
              if (
                student.institution_user_id == marked.attendee_id &&
                marked.attendance_type_id != ""
              ) {
                this.attendenceTypes.forEach((attendence) => {
                  if (attendence.id == marked.attendance_type_id) {
                    student.status = attendence.code;
                    student.attendenceId = attendence.id;
                    if (
                      this.existingAttendenceId != marked.attendance_date_id
                    ) {
                      student.disabled = true;
                    }
                  }
                });
              }
            });
          });

          this.sortedArray();

          this.$forceUpdate();
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    async sortedArray() {
      await this.studentAttendence.forEach((student, studentIndex) => {
        this.$set(this.markStudents.rollNumbersStatus, student.rollNumber, {
          status: null,
          currentType: null,
          studentIndex: studentIndex,
          rollNumber: student.rollNumber,
        });
      });

      await this.attendenceTypes.forEach((attendence) => {
        if (attendence.code != "P") {
          this.$set(this.markStudents, attendence.name, []);
        }
        this.studentAttendence.forEach((student) => {
          if (attendence.code == student.status && !student.disabled) {
            if (this.markStudents[attendence.name]) {
              this.markStudents[attendence.name].push(student.rollNumber);
              this.markStudents.rollNumbersStatus[
                student.rollNumber
              ].status = 1;
              this.markStudents.rollNumbersStatus[
                student.rollNumber
              ].currentType = attendence.name;
            }
          }
        });
      });
      this.$bvModal.show("markAttendenceForStudents");

      this.$toast.success(
        "<a href='https://play.google.com/store/apps/details?id=in.chipsnbytes.ezygo.ezygo_app1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img height='50' style='margin: -19px;'  alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>  ",
        {
          // position: "top",
          duration: 7000,
        }
      );
    },
    getCourseDetails() {
      // let mainUrl = this.$store.getters.getAPIKey.createCourse;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/institutionuser/courses`;
      axios
        .get(url)
        .then((response) => {
          this.courses = response.data;
          this.$emit("coursesToList", this.courses);
          this.coursesOptions = [
            { value: null, text: "Select Course", disabled: true },
          ];
          this.courseData = response.data;
          // this.$emit("courseCodes", this.courseData);

          this.courses.forEach((course) => {
            this.coursesOptions.push({
              value: {
                id: course.id,
                courseName: `${course.name}(${course.usersubgroup.code}-${course.usersubgroup.usergroup.code})`,
              },
              text: `${course.name}(${course.usersubgroup.code}-${course.usersubgroup.usergroup.code})`,
            });
          });
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    getSessions() {
      const url = this.$store.getters.getAPIKey.createSessions;
      axios
        .get(url)
        .then((response) => {
          this.sessionOptions = [];
          let sessions = response.data;
          sessions.forEach((session) => {
            this.sessionOptions.push({ value: session.id, text: session.name });
          });
          sessions.forEach((session) => {
            this.$set(this.allSessionDetails, session.id, {
              id: session.id,
              name: session.name,
            });
          });
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    async getCourseUsers() {
      this.selectedUsers = [];
      this.studentAttendence = [];
      if (!this.editAttendenceFromListing) {
        this.createAttendence.usersubgroup_id = null;
      }

      this.courseUsers = {};
      this.courseUsers[this.createAttendence.course_id.id] = [];
      const usergroupId = await this.courses.findIndex((course) => {
        return course.id == this.createAttendence.course_id.id;
      });
      await this.studentsRollNumber.forEach((course) => {
        if (course.pivot.course_id == this.createAttendence.course_id.id) {
          this.createAttendence.usersubgroup_id = this.courses[
            usergroupId
          ].usersubgroup_id;
          // course.institution_users.forEach((student) => {
          //   if (student.pivot.courserole_id == 2) {
          this.courseUsers[this.createAttendence.course_id.id].push(course);
          //   }
          // });
        }
      });
      if (
        this.createAttendence.course_id != null &&
        this.courseUsers[this.createAttendence.course_id.id]
      ) {
        this.studentAttendence = [];
        await this.courseUsers[this.createAttendence.course_id.id].forEach(
          (user) => {
            this.studentAttendence.push({
              id: user.id,
              institution_user_id: user.institution_user_id,
              name: user.first_name + " " + (user.last_name || ""),
              status: "",
              rollNumber: user.roll_number,
              attendenceId: null,
              disabled: false,
            });
          }
        );
      }
    },
    getAttendenceType() {
      const url = this.$store.getters.getAPIKey.attendenceType;
      axios
        .get(url)
        .then((response) => {
          this.attendenceTypes = response.data;
          this.fetchData = false;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    async markAttendence(
      attendenceCode,
      markAll,
      studentIndex,
      attendenceId,
      attendenceType
    ) {
      // this.markStudents[attendenceType + "Text"] = "";
      if (markAll) {
        await this.attendenceTypes.forEach((attendence) => {
          if (attendence.code != "P") {
            this.markStudents[attendence.name] = [];
          }
        });
        await this.studentAttendence.forEach((student) => {
          if (
            !student.disabled ||
            (!student.disabled &&
              this.rollNumbersToLimit[student.rollNumber] &&
              this.rollNumbersToLimit[student.rollNumber] == student.rollNumber)
          ) {
            student.status = attendenceCode;
            student.attendenceId = attendenceId;

            if (
              attendenceCode != "P" &&
              this.rollNumbersToLimit[student.rollNumber] != student.rollNumber
            ) {
              this.markStudents.rollNumbersStatus[
                student.rollNumber
              ].status = 1;
              this.markStudents.rollNumbersStatus[
                student.rollNumber
              ].currentType = attendenceType;

              this.markStudents[attendenceType].push(student.rollNumber);
            } else {
              this.markStudents.rollNumbersStatus[
                student.rollNumber
              ].status = null;
              this.markStudents.rollNumbersStatus[
                student.rollNumber
              ].currentType = attendenceType;
            }
          }
        });
      } else {
        this.studentAttendence[studentIndex].status = attendenceCode;
        this.studentAttendence[studentIndex].attendenceId = attendenceId;
        if (
          this.markStudents.rollNumbersStatus[
            this.studentAttendence[studentIndex].rollNumber
          ].currentType != null
        ) {
          this.markStudents.rollNumbersStatus[
            this.studentAttendence[studentIndex].rollNumber
          ].status = null;
          let requiredIndex;
          if (
            this.markStudents.rollNumbersStatus[
              this.studentAttendence[studentIndex].rollNumber
            ].currentType != "Present"
          ) {
            requiredIndex = await this.markStudents[
              this.markStudents.rollNumbersStatus[
                this.studentAttendence[studentIndex].rollNumber
              ].currentType
            ].findIndex((rollNo) => {
              return rollNo == this.studentAttendence[studentIndex].rollNumber;
            });
            this.markStudents[
              this.markStudents.rollNumbersStatus[
                this.studentAttendence[studentIndex].rollNumber
              ].currentType
            ].splice(requiredIndex, 1);
          }

          this.markStudents.rollNumbersStatus[
            this.studentAttendence[studentIndex].rollNumber
          ].status = 1;
          this.markStudents.rollNumbersStatus[
            this.studentAttendence[studentIndex].rollNumber
          ].currentType = attendenceType;
          if (attendenceCode == "P") {
            return;
          }
          this.markStudents[attendenceType].push(
            this.studentAttendence[studentIndex].rollNumber
          );
          this.$forceUpdate();
        }
        if (
          this.markStudents[
          this.markStudents.rollNumbersStatus[
            this.studentAttendence[studentIndex].rollNumber
          ].currentType
          ] == null
        ) {
          this.markStudents[attendenceType].push(
            this.studentAttendence[studentIndex].rollNumber
          );
          this.markStudents.rollNumbersStatus[
            this.studentAttendence[studentIndex].rollNumber
          ].status = 1;
          this.markStudents.rollNumbersStatus[
            this.studentAttendence[studentIndex].rollNumber
          ].currentType = attendenceType;
        }
      }
    },
    markLeftOversAsPresent(attendenceCode, attendenceId) {
      this.studentAttendence.forEach((attendence) => {
        if (
          !attendence.disabled &&
          attendence.status == "" &&
          this.markStudents.Present
        ) {
          attendence.status = attendenceCode;
          attendence.attendenceId = attendenceId;
        }
        if (
          !attendence.disabled &&
          attendence.status == attendenceCode &&
          !this.markStudents.Present
        ) {
          attendence.status = "";
          attendence.attendenceId = attendenceId;
        }
      });
    },
    async getRollNumbers(courseId) {
      courseId;

      this.fetchData = true;
      let status = null;
      let mainURL = this.$store.getters.getAPIKey.createStudent;
      const url = mainURL.replace(
        "course_id",
        this.createAttendence.course_id.id
      );

      await axios
        .get(url)
        .then((response) => {
          this.studentsRollNumber = response.data[0];
          status = true;

          // console.log(response);
        })
        .catch(() => {
          status = false;
          // console.log(error.response);
        });
      if (status) {
        await this.getCourseUsers();
      }
      this.fetchData = false;
    },
    async clearAll() {
      await this.attendenceTypes.forEach((attendence) => {
        if (attendence.code != "P") {
          this.markStudents[attendence.name] = [];
        }
      });
      await this.studentAttendence.forEach((student) => {
        if (!student.disabled) {
          this.markStudents.rollNumbersStatus[student.rollNumber].status = null;
          this.markStudents.rollNumbersStatus[
            student.rollNumber
          ].currentType = null;
          student.status = "";
          student.attendenceId = null;
        }
      });
    },
    clearResponse() {
      if (
        this.responseErrors["message"] &&
        this.responseErrors["attendance_date"]
      ) {
        this.getUsersBtn.text = "Go";
        this.getUsersBtn.disabled = false;
        this.editAttendance = false;
        this.cancel();
        this.responseErrors = {};
        this.existingAttendenceId = "";
      }
      setTimeout(() => {
        if (this.attendenceErrors.error) {
          this.getUsersBtn.text = "Go";
          this.getUsersBtn.disabled = false;
        }
        if (this.validationErrors.attendance_date) {
          this.validationErrors = {};
          this.getUsersBtn.text = "Go";
          this.getUsersBtn.disabled = false;
        }
        if (this.responseErrors.attendance_dates) {
          this.getUsersBtn.text = "Go";
          this.getUsersBtn.disabled = false;
          // this.responseErrors = {};
        }
        if (
          this.markBtn.text &&
          !this.responseErrors["message"] &&
          !this.responseErrors["attendance_date"]
        ) {
          this.markBtn.text = "Proceed";
          this.markBtn.disabled = false;
          this.responseErrors = {};
        }
        if (this.studentsMarked != "") {
          this.studentsMarked = "";
          this.cancel();
        }
        if (this.responseErrors.attendenceCreated) {
          this.getUsersBtn.text = "Go";
          this.getUsersBtn.disabled = true;
          this.responseErrors = {};
        }
      }, 3000);
    },
    cancel() {
      this.markStudents = {
        Present: false,
        rollNumbersStatus: {},
      };
      this.attendenceDates = false;
      this.markBtn.text = "Proceed";
      this.markBtn.disabled = false;
      this.responseErrors = {};
      this.attendenceIds = [];
      this.getUsersBtn.text = "Go";
      this.getUsersBtn.disabled = false;
      this.attendenceIds = [];
      this.studentAttendence = [];
      this.createAttendence.session_id = [];
      this.createAttendence.usersubgroup_id = null;
      this.createAttendence.course_id = null;
      this.createAttendence.date_time = "";
      this.existingAttendenceId = "";
      this.attendance_alert.absentees_on_marking_to_student = false;
      this.attendance_alert.absentees_on_marking_to_parent = false;
      this.$emit("closeEditFromAttendenceListing");
      this.$bvModal.hide("markAttendenceForStudents");
    },
    // when user disabled and there is no globel group for the course then suggest to create course group
    async createCourseGlobalgroup() {
      this.$bvModal.show("createCourseglobalgroupModel");
    },
  },
};
</script>

<style >
.blink_me {
  animation: blinker 1.5s linear infinite;
  color: forestgreen;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.attendenceMarking .list-group .list-group-item {
  border-style: none;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  #attendenceMarking {
    display: flex;
    flex-wrap: wrap;
  }

  #attendenceMarking button {
    margin-top: 10px;
  }
}

.markAttendence .modal-dialog {
  max-width: 100%;
  max-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.markAttendence .modal-body {
  overflow: scroll;
}
</style>
