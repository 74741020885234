<template>
  <div>
    <div class="attendenceListing">
      <div v-if="screenWidth > 600">
        <div class="text-right mb-2 videoAttendence">
          <!-- <b-button @click="openYoutubeLinks('Attendance')" style="backgroundColor: #FF0000; border: none" size="sm">
            <i class="fab fa-youtube"></i>
            Video Tutorial
          </b-button> -->
        </div>
        <b-tabs content-class="mt-3" v-model="tabIndex" lazy>
          <b-tab title="Attendance Marking">
            <attendence-mark
              ref="attendenceMark"
              @coursesToList="getCourses"
              @closeEditFromAttendenceListing="attendenceListingEdit = false"
              :attendenceDateFromListing="attendenceDateFromListing"
              :attendenceInfo="attendenceInfo"
              :attendenceListingEdit="attendenceListingEdit"
            ></attendence-mark>
          </b-tab>
          <b-tab title="Attendance List">
            <attendence-list
              @editAttendenceFromListing="editAttendenceFromListing"
              :courses="courses"
            ></attendence-list>
          </b-tab>
        </b-tabs>
      </div>
      <div v-else>
        <b-tabs content-class="mt-3" fill v-model="tabIndex" lazy>
          <b-tab title="Attendance Marking">
            <attendence-mark
              ref="markAttendence"
              @coursesToList="getCourses"
              :attendenceInfo="attendenceInfo"
            ></attendence-mark>
          </b-tab>
          <b-tab title="Attendance List">
            <attendence-list :courses="courses"></attendence-list>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import AttendenceList from "./AttendenceList";
import AttendenceMark from "./AttendenceMark";
import moment from "moment";
// import AttendenceMobileView from "./AttendenceMobileView.vue";
export default {
  async created() {
    await window.addEventListener("resize", this.handleResize);
    await this.handleResize();
    this.attendenceListingEdit = false;
  },
  props: ["attendenceInfo"],
  components: {
    attendenceList: AttendenceList,
    attendenceMark: AttendenceMark,
    // attendenceMobileView: AttendenceMobileView,
  },
  data() {
    return {
      screenWidth: 0,
      attendenceView: false,
      courses: [],
      tabIndex: 0,
      attendenceDateFromListing: {},
      attendenceListingEdit: false,
    };
  },
  methods: {
    async editAttendenceFromListing(attendenceData) {
      this.tabIndex--;
      this.attendenceListingEdit = await attendenceData.edit;
      this.attendenceDateFromListing = await {
        session_id: [attendenceData.session],
        usersubgroup_id: attendenceData.usersubgroup,
        course_id: attendenceData.course,
        date_time: new Date(moment(attendenceData.date).format("YYYY-MM-DD")),
      };
      this.attendenceView = await attendenceData.edit;
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
      this.$forceUpdate();
    },
    async fillAttendenceDate() {
      await this.$refs.attendenceMark;
      this.$refs.attendenceMark.markAttendenceFromMySchedule();
      this.$forceUpdate();
    },
    getCourses(value) {
      this.courses = value;
    },
  },
};
</script>

<style >
.attendenceMarking .list-group .list-group-item {
  border-style: none;
}
.active-breadcrumb span {
  color: #65a765 !important;
  border-bottom: 1px solid black;
  font-size: 14px !important;
}
.attendenceListing ul {
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}
.attendenceListing ul li {
  border: 3px solid #f8f9fa !important;
}

.attendenceListing .tab-content {
  border: none !important;
}

.attendenceListing .videoAttendence i {
  font-size: 10px !important;
}
</style>
