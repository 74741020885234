<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-container v-else>
      <b-row>
        <b-col md="12" id="attendenceCards">
          <b-overlay
            :show="deleteAttendance"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="md"
          >
            <b-modal
              id="deleteAttendence"
              no-close-on-backdrop
              no-close-on-keyboard
              no-close-on-esc
              hide-header-close
              hide-footer
              hide-header
              centered
              size="md"
            >
              <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
                <span> Are you sure you want to delete this attendance ? </span>
              </h6>
              <div class="buttons">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="deleteAttendanceDates()"
                  >Proceed</b-button
                >
                <b-button
                  size="sm"
                  variant="danger"
                  class="ml-2"
                  @click="$bvModal.hide('deleteAttendence')"
                  >Cancel</b-button
                >
              </div>
            </b-modal>
            <div
              class="refreshbtn d-flex justify-content-between align-items-center p-2 w-100"
              style="
                border: 2px solid #eeeeeb;
                border-left-style: none;
                border-right-style: none;
              "
            >
              <b-button variant="info" size="sm" @click="getAttendenceList()">
                <i class="fas fa-sync-alt mr-1"></i>Refresh
              </b-button>
            </div>
            <!-- <span class="text-info btn p-0 m-0 mb-1 ml-2" @click="getAttendenceList()">
              <i class="fas fa-sync-alt fa-2x"></i>
            </span> -->
            <div class="selectAttendenceType d-flex mt-2 mb-2">
              <b-form-select
                :options="attendenceTypesOptions"
                @change="getMyAttendanceDates()"
                v-model="attendenceTypeSelected"
              ></b-form-select>

              <b-form-select
                class="ml-2"
                :options="coursesOptions"
                v-model="selectedCourse"
                @change="sortAttendanceDates"
              ></b-form-select>
            </div>

            <div class="attendenceListView" v-if="$screenSize > 600">
              <div class="table-responsive">
                <table class="table table-bordered table-striped">
                  <thead class="thead-dark">
                    <tr>
                      <th class="align-middle">
                        Course Name,
                        <span
                          style="color: #76ffb8; font-weight: bold; font-size: 15px !important;"
                          >Total:{{ filterAttendanceDates.length }}</span
                        >
                      </th>
                      <th class="align-middle">Code</th>
                      <th class="align-middle">Date & Session</th>
                      <th class="align-middle">Status</th>
                      <th class="align-middle">Marked By</th>
                      <th class="align-middle">Topic</th>
                      <th class="align-middle">Delivery Type</th>
                      <th class="align-middle">Class Type</th>
                      <th class="align-middle"></th>
                      <th class="align-middle"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(attendanceDate,
                      dateIndex) in filterAttendanceDates"
                      :key="dateIndex"
                    >
                      <td class="align-middle">
                        {{ attendanceDate.course.name }}
                      </td>
                      <td class="align-middle">
                        {{
                          courseCodes[attendanceDate.course_id]
                            ? courseCodes[attendanceDate.course_id].code
                            : "Code"
                        }}
                      </td>
                      <td class="align-middle text-center">
                        {{ attendanceDate.date_time.format("DD/MM/YYYY") }}
                        <br />
                        Session - {{ attendanceDate.session.name }}
                      </td>
                      <td class="align-middle">
                        <div class="attendence p-2 d-flex flex-column">
                          <h6
                            v-for="(attendance_count,
                            attendance_type_id) in attendanceDate.attendance_type_counts"
                            :key="attendance_type_id"
                            :class="
                              'btn m-0 p-0 font-weight-bolder ml-3 text-' +
                                attendenceTypes[attendance_type_id].color
                            "
                          >
                            <p
                              :id="
                                attendenceTypes[attendance_type_id].name +
                                  '-' +
                                  attendanceDate.id +
                                  '-' +
                                  attendenceTypes[attendance_type_id].id
                              "
                              class="m-0 p-0"
                              @click="
                                getStudents(
                                  attendanceDate.id,
                                  attendenceTypes[attendance_type_id].id
                                )
                              "
                            >
                              {{ attendenceTypes[attendance_type_id].name }}-{{
                                attendance_count
                              }}
                            </p>

                            <b-popover
                              :target="
                                attendenceTypes[attendance_type_id].name +
                                  '-' +
                                  attendanceDate.id +
                                  '-' +
                                  attendenceTypes[attendance_type_id].id
                              "
                              triggers="click"
                              placement="top"
                            >
                              <template #title
                                >{{
                                  attendenceTypes[attendance_type_id].name
                                }}
                                Students</template
                              >
                              <p class="text-warning" v-if="attendenceStatus">
                                Please Wait...
                              </p>
                              <b-list-group
                                v-else
                                :style="
                                  studentsForAttendence.length > 5
                                    ? {
                                        'overflow-y': 'scroll',
                                        height: '200px',
                                      }
                                    : ''
                                "
                              >
                                <b-list-group-item
                                  v-for="(student,
                                  studentIndex) in studentsForAttendence"
                                  :key="studentIndex"
                                  style="
                                    border-style: none;
                                    font-size: 14px;
                                    font-weight: 600;
                                  "
                                  >{{ student.roll_number }}-{{
                                    student.attendee.first_name || " "
                                  }}
                                  {{
                                    student.attendee.last_name || " "
                                  }}</b-list-group-item
                                >
                              </b-list-group>
                            </b-popover>
                          </h6>
                        </div>
                      </td>
                      <td class="align-middle">
                        <span v-if="attendanceDate.institution_user">
                          {{
                            attendanceDate.institution_user.first_name || " "
                          }}
                          {{
                            attendanceDate.institution_user.last_name || " "
                          }}</span
                        >
                      </td>
                      <td class="align-middle">
                        <b-modal
                          :id="'lessonPlans' + attendanceDate.id"
                          title="Attach Topic"
                          no-close-on-backdrop
                          no-close-on-keyboard
                          no-close-on-esc
                          hide-footer
                          size="lg"
                        >
                          <lesson-attached
                            :attenddenceId="attendanceDate.id"
                            :courseId="attendanceDate.course_id"
                          ></lesson-attached>
                        </b-modal>
                        <h6 class="text-dark font-weight-bolder d-flex">
                          <p
                            class="text-info btn p-0 m-0"
                            :id="'lesson' + dateIndex"
                            @mouseover="
                              getAttachedLessonPlans(attendanceDate.id)
                            "
                            @blur="closeLessonPlan()"
                          >
                            Topic
                          </p>
                          <span
                            class="btn m-0 p-0 text-info"
                            @click="
                              $bvModal.show('lessonPlans' + attendanceDate.id)
                            "
                            ><i class="fas fa-plus-circle ml-2"></i
                          ></span>
                        </h6>
                        <b-popover
                          ref="lessonPopUp"
                          :target="'lesson' + dateIndex"
                          triggers="hover"
                          placement="top"
                        >
                          <template #title>Lesson Plans</template>
                          <p class="text-warning" v-if="lessonLoading != ''">
                            Please Wait...
                          </p>
                          <b-list-group v-else>
                            <b-list-group-item
                              v-if="lessonList.length == 0"
                              class="text-danger"
                              >No Topics found!</b-list-group-item
                            >
                            <b-list-group-item
                              v-for="(lesson, lessonIndex) in lessonList"
                              :key="lessonIndex"
                              >{{ lesson.lesson }}</b-list-group-item
                            >
                          </b-list-group>
                        </b-popover>
                      </td>
                      <td class="align-middle">
                        <b-modal
                          :id="'deliveryType' + +attendanceDate.id"
                          title="Attach Delivery Type"
                          no-close-on-backdrop
                          no-close-on-keyboard
                          no-close-on-esc
                          hide-footer
                          size="lg"
                        >
                          <delivery-attached
                            :attenddenceId="attendanceDate.id"
                            :courseId="attendanceDate.course_id"
                          ></delivery-attached>
                        </b-modal>
                        <h6 class="text-dark font-weight-bolder">
                          <p
                            class="text-info btn p-0 m-0"
                            :id="'delivery' + dateIndex"
                            @mouseover="
                              getAttachedDeliveryTypes(attendanceDate.id)
                            "
                            @blur="closeDeliveryType()"
                          >
                            Delivery Type
                          </p>
                          <span
                            class="btn m-0 p-0 text-info"
                            @click="
                              $bvModal.show('deliveryType' + attendanceDate.id)
                            "
                            ><i class="fas fa-plus-circle ml-2"></i
                          ></span>
                        </h6>
                        <b-popover
                          ref="deliveryPopUp"
                          :target="'delivery' + dateIndex"
                          triggers="hover"
                          placement="top"
                        >
                          <template #title>Delivery Type</template>
                          <p class="text-warning" v-if="lessonLoading != ''">
                            Please Wait...
                          </p>
                          <b-list-group v-else>
                            <b-list-group-item
                              v-if="lessonList.length == 0"
                              class="text-danger"
                              >No Delivery Type attached yet!</b-list-group-item
                            >
                            <b-list-group-item
                              v-else
                              v-for="(lesson, lessonIndex) in lessonList"
                              :key="lessonIndex"
                              >{{ lesson.name }}</b-list-group-item
                            >
                          </b-list-group>
                        </b-popover>
                      </td>
                      <td class="align-middle">
                        <b-popover
                          :target="'classType' + dateIndex"
                          triggers="hover"
                          placement="top"
                        >
                          <b-alert
                            variant="warning"
                            show
                            class="text-center font-weight-bolder"
                            v-if="classLoading"
                          >
                            Please wait...
                          </b-alert>
                          <span
                            class="font-weight-bolder text-info text-center"
                            v-else
                          >
                            {{
                              classType.classtype_id == null
                                ? "Regular"
                                : classType.classtype.name
                            }}
                          </span>
                        </b-popover>
                        <b-modal
                          :id="'classType' + +attendanceDate.id"
                          title="Attach Class Type"
                          no-close-on-backdrop
                          no-close-on-keyboard
                          no-close-on-esc
                          hide-footer
                          size="md"
                          centered
                        >
                          <ClassTypes
                            :attendanceId="attendanceDate.id"
                            :classTypeId="attendanceDate.classtype_id"
                            :attendenceList="true"
                            :muliple="false"
                            @classTypeAttached="
                              $bvModal.hide('classType' + +attendanceDate.id)
                            "
                          />
                        </b-modal>
                        <span
                          class="text-dark font-weight-bolder"
                          :id="'classType' + dateIndex"
                          @mouseenter="getClassType(attendanceDate.id)"
                        >
                          <span class="text-info">
                            Class Type
                          </span>
                          <span
                            class="btn m-0 p-0 text-info"
                            @click="
                              $bvModal.show('classType' + +attendanceDate.id)
                            "
                            ><i class="fas fa-plus-circle ml-2"></i
                          ></span>
                        </span>
                      </td>
                      <td class="align-middle text-center">
                        <span
                          class="btn text-info p-0 m-0"
                          @click="
                            getAttendenceDetails(
                              attendanceDate.date_time,
                              attendanceDate.session_id,
                              {
                                id: attendanceDate.course_id,
                                courseName: attendanceDate.course.name,
                              },
                              attendanceDate.usersubgroup_id
                            )
                          "
                        >
                          <i class="far fa-edit"></i>
                        </span>
                      </td>
                      <td class="align-middle text-center">
                        <span
                          class="text-danger btn p-0 m-0"
                          @click="
                            prepareAttendenceDeletion(
                              attendanceDate.id,
                              dateIndex
                            )
                          "
                          ><i class="fas fa-trash"></i
                        ></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="attendenceListing" v-else>
              <div class="attendenceCard">
                <h5
                  class="w-100 text-danger text-center"
                  v-if="attendanceDates.length == 0"
                >
                  <br />
                  No attendance created yet!
                </h5>
                <div v-else>
                  <p
                    class="w-100 text-danger text-center font-weight-bolder"
                    v-if="filterAttendanceDates.length == 0"
                  >
                    No Data Found!
                  </p>

                  <div class="d-flex flex-wrap" v-else>
                    <b-card
                      no-body
                      header-bg-variant="white"
                      v-for="(attendanceDate,
                      dateIndex) in filterAttendanceDates"
                      :key="dateIndex"
                      class="ml-4"
                      :style="
                        dateIndex % 2 === 0
                          ? { backgroundColor: '#F0F0F0' }
                          : { backgroundColor: '#FFFFFF' }
                      "
                    >
                      <template
                        #header
                        :style="
                          dateIndex % 2 === 0
                            ? { backgroundColor: '#F0F0F0' }
                            : { backgroundColor: '#FFFFFF' }
                        "
                      >
                        <div>
                          <div
                            class="d-flex justify-content-between align-items-baseline"
                          >
                            <h4 class="font-weight-bolder">
                              {{ attendanceDate.course.name }}
                            </h4>
                            <span
                              class="text-danger btn p-0 m-0"
                              @click="
                                prepareAttendenceDeletion(
                                  attendanceDate.id,
                                  dateIndex
                                )
                              "
                              ><i class="fas fa-trash"></i
                            ></span>
                          </div>
                          <h6 class="text-dark font-weight-bolder">
                            {{
                              courseCodes[attendanceDate.course_id]
                                ? courseCodes[attendanceDate.course_id].code
                                : "Code"
                            }}
                          </h6>
                        </div>

                        <br />
                      </template>
                      <b-card-body>
                        <b-modal
                          :id="'deliveryType' + +attendanceDate.id"
                          title="Attach Delivery Type"
                          no-close-on-backdrop
                          no-close-on-keyboard
                          no-close-on-esc
                          hide-footer
                          size="lg"
                        >
                          <delivery-attached
                            :attenddenceId="attendanceDate.id"
                            :courseId="attendanceDate.course_id"
                          ></delivery-attached>
                        </b-modal>

                        <b-modal
                          :id="'lessonPlans' + attendanceDate.id"
                          title="Attach Topic"
                          no-close-on-backdrop
                          no-close-on-keyboard
                          no-close-on-esc
                          hide-footer
                          size="lg"
                        >
                          <lesson-attached
                            :attenddenceId="attendanceDate.id"
                            :courseId="attendanceDate.course_id"
                          ></lesson-attached>
                        </b-modal>
                        <div class="date p-2">
                          <h6 class="font-weight-bolder">
                            {{ attendanceDate.date_time }}
                          </h6>
                          <h6 class="font-weight-bolder">
                            {{ attendanceDate.session.name }}
                          </h6>
                        </div>
                        <br />

                        <div class="attendence p-2 d-flex flex-wrap">
                          <h6
                            v-for="(attendance_count,
                            attendance_type_id) in attendanceDate.attendance_type_counts"
                            :key="attendance_type_id"
                            :class="
                              'font-weight-bolder ml-3 text-' +
                                attendenceTypes[attendance_type_id].color
                            "
                          >
                            <p
                              :id="
                                attendenceTypes[attendance_type_id].name +
                                  '-' +
                                  attendanceDate.id +
                                  '-' +
                                  attendenceTypes[attendance_type_id].id
                              "
                              class="m-0 p-0"
                              @click="
                                getStudents(
                                  attendanceDate.id,
                                  attendenceTypes[attendance_type_id].id
                                )
                              "
                            >
                              {{ attendenceTypes[attendance_type_id].name }}-{{
                                attendance_count
                              }}
                            </p>

                            <b-popover
                              :target="
                                attendenceTypes[attendance_type_id].name +
                                  '-' +
                                  attendanceDate.id +
                                  '-' +
                                  attendenceTypes[attendance_type_id].id
                              "
                              triggers="click"
                              placement="top"
                            >
                              <template #title
                                >{{
                                  attendenceTypes[attendance_type_id].name
                                }}
                                Students</template
                              >
                              <p class="text-warning" v-if="attendenceStatus">
                                Please Wait...
                              </p>
                              <b-list-group
                                v-else
                                :style="
                                  studentsForAttendence.length > 5
                                    ? {
                                        'overflow-y': 'scroll',
                                        height: '200px',
                                      }
                                    : ''
                                "
                              >
                                <b-list-group-item
                                  v-for="(student,
                                  studentIndex) in studentsForAttendence"
                                  :key="studentIndex"
                                  style="
                                    border-style: none;
                                    font-size: 14px;
                                    font-weight: 600;
                                  "
                                  >{{ student.roll_number }}-
                                  {{ student.attendee.first_name || " " }}
                                  {{
                                    student.attendee.last_name || " "
                                  }}</b-list-group-item
                                >
                              </b-list-group>
                            </b-popover>
                          </h6>
                        </div>
                        <br />
                        <div
                          class="lesson d-flex justify-content-between flex-wrap"
                        >
                          <h6 class="text-dark font-weight-bolder d-flex">
                            <p
                              class="text-info btn p-0 m-0"
                              :id="'lesson' + dateIndex"
                              @mouseover="
                                getAttachedLessonPlans(attendanceDate.id)
                              "
                              @mouseout="closeLessonPlan()"
                            >
                              Topic
                            </p>
                            <span
                              class="btn m-0 p-0 text-info"
                              @click="
                                $bvModal.show('lessonPlans' + attendanceDate.id)
                              "
                              ><i class="fas fa-plus-circle ml-2"></i
                            ></span>
                          </h6>
                          <b-popover
                            ref="lessonPopUp"
                            :target="'lesson' + dateIndex"
                            triggers="hover"
                            placement="top"
                          >
                            <template #title>Lesson Plans</template>
                            <p class="text-warning" v-if="lessonLoading != ''">
                              Please Wait...
                            </p>
                            <b-list-group v-else>
                              <b-list-group-item
                                v-for="(lesson, lessonIndex) in lessonList"
                                :key="lessonIndex"
                                >{{ lesson.lesson }}</b-list-group-item
                              >
                            </b-list-group>
                          </b-popover>

                          <h6 class="text-dark font-weight-bolder">
                            <p
                              class="text-info btn p-0 m-0"
                              :id="'delivery' + dateIndex"
                              @mouseover="
                                getAttachedDeliveryTypes(attendanceDate.id)
                              "
                              @mouseout="closeDeliveryType()"
                            >
                              Delivery Type
                            </p>
                            <span
                              class="btn m-0 p-0 text-info"
                              @click="
                                $bvModal.show(
                                  'deliveryType' + attendanceDate.id
                                )
                              "
                              ><i class="fas fa-plus-circle ml-2"></i
                            ></span>
                          </h6>
                          <b-popover
                            ref="deliveryPopUp"
                            :target="'delivery' + dateIndex"
                            triggers="hover"
                            placement="top"
                          >
                            <template #title>Delivery Type</template>
                            <p class="text-warning" v-if="lessonLoading != ''">
                              Please Wait...
                            </p>
                            <b-list-group v-else>
                              <b-list-group-item
                                v-if="lessonList.length == 0"
                                class="text-danger"
                                >No Delivery Type attached
                                yet!</b-list-group-item
                              >
                              <b-list-group-item
                                v-else
                                v-for="(lesson, lessonIndex) in lessonList"
                                :key="lessonIndex"
                                >{{ lesson.name }}</b-list-group-item
                              >
                            </b-list-group>
                          </b-popover>
                        </div>
                      </b-card-body>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import LessonsAttach from "./LessonsAttach.vue";
import DeliveryAttach from "./DeliveryAttach.vue";
import moment from "moment";
import ClassTypes from "./ClassTypes";

export default {
  // props: ["courses"],
  async created() {
    await this.getAttendenceList();
  },

  components: {
    lessonAttached: LessonsAttach,
    deliveryAttached: DeliveryAttach,
    ClassTypes,
  },
  data() {
    return {
      fetchData: false,
      deleteAttendance: false,
      attendenceTypes: [],
      attendanceDates: [],
      lessonList: [],
      lessonLoading: "",
      courseCodes: {},
      studentsForAttendence: [],
      attendenceStatus: true,
      coursesOptions: [],
      coursesObject: {},
      selectedCourse: null,
      sortCourse: null,
      activeCourseIndex: null,
      attendenceTypesOptions: [
        { value: 1, text: "My Attendance" },
        { value: 2, text: "All Attendance" },
      ],
      attendenceTypeSelected: 1,
      attendenceDeletionStatus: {
        id: null,
        index: null,
      },
      classLoading: false,
      classType: {},
    };
  },
  computed: {
    filterAttendanceDates() {
      if (this.sortCourse != null) {
        return this.attendanceDates.filter((attendance) => {
          return attendance.course_id == this.sortCourse;
        });
      } else {
        return this.attendanceDates;
      }
    },
  },
  methods: {
    async getClassType(attendanceId) {
      this.classLoading = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancedates/${attendanceId}?with_classtype=1`;
      await this.$axios.get(url).then((res) => {
        this.classType = res.data;
      });
      this.classLoading = false;
    },
    async prepareAttendenceDeletion(attendenceId, attendenceIndex) {
      this.attendenceDeletionStatus.id = await attendenceId;
      this.attendenceDeletionStatus.index = await attendenceIndex;
      this.$bvModal.show("deleteAttendence");
    },
    async getAttendenceDetails(date, session, course, usersubgroup) {
      let setData = await {
        date: moment(date).format("YYYY/MM/DD"),
        session: session,
        course: this.coursesObject[course.id],
        usersubgroup: usersubgroup,
        edit: true,
      };

      this.$emit("editAttendenceFromListing", setData);
    },
    async getAttendenceList() {
      await this.getCourseDetails();
      await this.getAttendenceTypes();
      await this.getAttendanceDates();
    },
    sortAttendanceDates() {
      // this.selectedCourse = null;
      const theCourseId = this.selectedCourse;

      if (this.attendenceTypeSelected == 2) {
        this.sortCourse = theCourseId;
        this.selectedCourse = theCourseId;
        return this.getMyAttendanceDates();
      } else {
        this.sortCourse = theCourseId;
        this.selectedCourse = theCourseId;
        // return this.getAttendanceDates();
      }
    },
    async getCourseDetails() {
      // let mainUrl = this.$store.getters.getAPIKey.createCourse;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/institutionuser/courses`;
      await axios
        .get(url)
        .then((response) => {
          this.courses = response.data;
          this.coursesOptions = [
            { value: null, text: "Sort By Course", disabled: true },
          ];
          this.courses.forEach((course) => {
            this.coursesOptions.push({
              value: course.id,
              text: `${course.name}`,
            });
            this.$set(this.coursesObject, course.id, {
              id: course.id,
              courseName: `${course.name}(${course.usersubgroup.code}-${course.usersubgroup.usergroup.code})`,
            });
          });
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    getStudents(attendenceDate, attendenceType) {
      this.$root.$emit("bv::hide::popover");
      this.attendenceStatus = true;
      this.studentsForAttendence = [];
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancedate/${attendenceDate}/attendancetypes/${attendenceType}/attendances`;
      axios
        .get(url)
        .then((response) => {
          this.studentsForAttendence = response.data;
          this.attendenceStatus = false;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    sortedArray() {
      this.studentsForAttendence.sort(function(a, b) {
        return a.rollNumber - b.rollNumber || a.name.localeCompare(b.name);
      });
    },
    async deleteAttendanceDates() {
      this.$bvModal.hide("deleteAttendence");
      this.deleteAttendance = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancedates/${this.attendenceDeletionStatus.id}`;
      await axios
        .delete(url)
        .then(() => {
          this.filterAttendanceDates.splice(
            this.attendenceDeletionStatus.index,
            1
          );

          this.attendenceDeletionStatus.id = null;
          this.attendenceDeletionStatus.index = null;
          this.deleteAttendance = false;
          // console.log(response);
        })
        .catch(() => {
          this.deleteAttendance = false;
          // console.log(error.response);
        });
    },
    async getAttendenceTypes() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.attendenceType;
      await axios
        .get(url)
        .then((response) => {
          response.data.forEach((attendenceType) => {
            this.attendenceTypes[attendenceType.id] = attendenceType;
          });
          this.fetchData = false;
          // this.attendenceTypes = response.data;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    async getAttendanceDates() {
      this.fetchData = true;
      let attendanceDatesUrl =
        this.$store.getters.getAPIKey.mainAPI + `/attendancedates/withcounts`;
      await axios
        .get(attendanceDatesUrl)
        .then((response) => {
          // console.log(response);
          this.courses.forEach((course) => {
            this.courseCodes[course.id] = {};
            this.courseCodes[course.id] = {
              code: `${course.code}-${course.usersubgroup.code}-${course.usersubgroup.usergroup.code}`,
            };
          });
          var attendanceDates = response.data;
          attendanceDates.forEach((attendanceDate) => {
            attendanceDate["attendance_type_counts"] = {};
            //initiate object
            attendanceDate.attendances.forEach((attendance) => {
              attendanceDate.attendance_type_counts[
                attendance.attendance_type_id
              ] = 0;
            });
            //count it
            attendanceDate.attendances.forEach((attendance) => {
              attendanceDate.attendance_type_counts[
                attendance.attendance_type_id
              ] += 1;
            });
            // console.log(attendanceDate);
          });

          this.attendanceDates = attendanceDates;
          this.attendanceDates.forEach((attendence) => {
            let date = attendence.date_time;
            attendence.date_time = moment(date);
          });
          this.sortAttendenceByDate();

          this.fetchData = false;
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    async getMyAttendanceDates() {
      if (this.attendenceTypeSelected == 2) {
        // this.selectedCourse = null;
        // this.sortCourse = null;
        ("NOTHING");
      } else {
        this.selectedCourse = null;
        this.sortCourse = null;
        return await this.getAttendanceDates();
      }

      this.fetchData = true;
      let courseId;
      if (this.sortCourse == null) {
        this.activeCourseIndex = 0;
        this.selectedCourse = await this.coursesOptions[1].value;
        courseId = await this.coursesOptions[1].value;
      } else {
        courseId = this.sortCourse;
      }
      let attendanceDatesUrl =
        this.$store.getters.getAPIKey.mainAPI +
        `/courses/${courseId}/attendancedates/withcounts`;

      await axios
        .get(attendanceDatesUrl)
        .then((response) => {
          this.courses.forEach((course) => {
            this.courseCodes[course.id] = {};
            this.courseCodes[course.id] = {
              code: `${course.code}-${course.usersubgroup.code}-${course.usersubgroup.usergroup.code}`,
            };
          });
          var attendanceDates = response.data;
          attendanceDates.forEach((attendanceDate) => {
            attendanceDate["attendance_type_counts"] = {};
            //initiate object
            attendanceDate.attendances.forEach((attendance) => {
              attendanceDate.attendance_type_counts[
                attendance.attendance_type_id
              ] = 0;
            });
            //count it
            attendanceDate.attendances.forEach((attendance) => {
              attendanceDate.attendance_type_counts[
                attendance.attendance_type_id
              ] += 1;
            });
            // console.log(attendanceDate);
          });

          this.attendanceDates = attendanceDates;
          this.attendanceDates.forEach((attendence) => {
            let date = attendence.date_time;
            attendence.date_time = moment(date);
          });
          this.sortAttendenceByDate();
        })
        .catch((error) => {
          error;
          // console.log(error.response);
        });
      this.fetchData = false;
    },
    sortAttendenceByDate() {
      this.attendanceDates.sort((a, b) => {
        a = new Date(a.date_time);
        b = new Date(b.date_time);
        var results = a > b ? -1 : a < b ? 1 : 0;
        return results * 1;
        // return new Date(b.date_time) - new Date(a.date_time);
      });
    },
    async addLessonPlan(courseId, attendenceId) {
      this.$refs.lessonAttached.courseId = courseId;
      this.$refs.lessonAttached.attendenceId = attendenceId;
      await this.$refs.lessonAttached.getLessonPlans();
      this.$refs.lessonAttached.$bvModal.show("lessonPlans");
    },
    async addDeliveryType(courseId, attendenceId) {
      this.$refs.deliveryAttached.courseId = courseId;
      this.$refs.deliveryAttached.attendenceId = attendenceId;
      await this.$refs.deliveryAttached.getdeliveryType();
      // await this.$refs.lessonAttached.getAttachedLessonPlans();
      this.$refs.lessonAttached.$bvModal.show("deliveryType");
    },
    getAttachedLessonPlans(attendenceId) {
      this.lessonLoading = "Please Wait...";
      let lessonPlan = this.$store.getters.getAPIKey
        .getAttendenceDatesLessonPlans;
      const url = lessonPlan.replace("attendancedateId", attendenceId);
      axios
        .get(url)
        .then((response) => {
          this.lessonLoading = "";
          this.lessonList = [];
          this.lessonList = response.data;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    getAttachedDeliveryTypes(attendenceId) {
      this.lessonLoading = "Please Wait...";
      let lessonPlan = this.$store.getters.getAPIKey
        .getAttendenceDatesDeliveryTypes;
      const url = lessonPlan.replace("attendancedateId", attendenceId);
      axios
        .get(url)
        .then((response) => {
          // console.log(response);
          this.lessonLoading = "";
          this.lessonList = [];
          this.lessonList = response.data;
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    closeLessonPlan() {
      () => this.$refs.lessonPopUp.$emit("close");
      this.lessonList = [];
      // this.$root.$emit("bv::hide::popover");
    },
    closeDeliveryType() {
      () => this.$refs.deliveryPopUp.$emit("close");
      this.lessonList = [];
      // this.$root.$emit("bv::hide::popover");
    },
  },
};
</script>

<style >
#attendenceCards .attendenceListing .attendenceCard {
  display: flex;
  flex-wrap: wrap;
}
#attendenceCards .attendenceListing .card {
  width: 300px;
  margin-top: 20px;
}
.refreshbtn button i {
  font-size: 12px !important;
}
</style>
