<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else>
      <div class="existing" v-if="existingPlans.length != 0">
        <b-overlay :show="loadPlan" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark">
              <tr>
                <th>Existing Delivery</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(plan, planIndex) in existingPlans" :key="planIndex">
                <td class="text-align">{{ plan.name }}</td>
                <td class="text-align text-center">
                  <span class="btn p-0 m-0 text-danger" @click="detachLesson(plan.id, planIndex)"><i
                      class="fas fa-trash ml-3"></i></span>
                </td>
              </tr>
            </tbody>
          </table>
        </b-overlay>
      </div>

      <b-form @submit.prevent="attachDelivery()" class="border-0">
        <b-form-group>
          <b-list-group>
            <b-list-group-item class="text-danger" v-if="lessonList.length == 0">No plans created for this course yet!
            </b-list-group-item>
            <b-list-group-item v-else>
              <div v-if="lessonOptions.length == 0">
                <p class="text-warning">All lessons has been attcahed.</p>
              </div>
              <div v-else>
                <b-form-checkbox-group :options="lessonOptions" v-model="selectedLessons"></b-form-checkbox-group>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["attenddenceId", "courseId"],
  created() {
    this.getdeliveryType();
  },
  data() {
    return {
      fetchData: false,
      loadPlan: false,
      lessonPlans: [],
      lessons: [],
      lessonOptions: [],
      selectedLessons: [],
      lessonList: [],
      existingPlans: [],
      attachLessonBtn: {
        text: "Add",
        disabled: false,
      },
    };
  },
  methods: {
    async getdeliveryType() {
      this.fetchData = true;
      let url = this.$store.getters.getAPIKey.deliveryType;

      axios
        .get(url)
        .then((response) => {
          // console.log(response);
          this.lessonList = [];
          this.lessonOptions = [];
          this.lessonList = response.data;

          this.lessonList.forEach((lesson) => {
            this.lessonOptions.push({
              value: lesson.id,
              text: lesson.name,
            });
          });
          if (this.lessonList.length != 0) {
            this.getAttachedDeliveryTypes();
          } else {
            this.fetchData = false;
          }
        })
        .catch((error) => {
          this.fetchData = false;
          error;
          //   console.log(error.response);
        });
    },
    async attachDelivery() {
      let status = null;
      let lessonPlan = this.$store.getters.getAPIKey.attachDeliveryType;
      const url = lessonPlan.replace("attendancedateId", this.attenddenceId);
      const attachedLessons = {
        coursedeliverymethod_ids: this.selectedLessons,
      };

      await axios
        .post(url, attachedLessons)
        .then(() => {
          status = true;
        })
        .catch(() => {
          status = false;
          this.clearResponse();
        });
      return status;
    },
    async detachLesson(lessonId, lessonIndex) {
      this.loadPlan = true;
      let lessonPlan = this.$store.getters.getAPIKey.detachDeliveryType;
      const url = lessonPlan.replace("attendancedateId", this.attenddenceId);
      const attachedLessons = {
        coursedeliverymethod_ids: [],
      };
      await attachedLessons.coursedeliverymethod_ids.push(lessonId);
      axios
        .post(url, attachedLessons)
        .then(() => {
          this.existingPlans.splice(lessonIndex, 1);
          this.getdeliveryType();
          this.getAttachedDeliveryTypes();
          this.selectedLessons = [];
          this.loadPlan = false;
          // console.log(response);
        })
        .catch(() => {
          this.loadPlan = false;

          // console.log(error.response);
        });
    },
    getAttachedDeliveryTypes() {
      let lessonPlan = this.$store.getters.getAPIKey.getAttendenceDatesDeliveryTypes;
      const url = lessonPlan.replace("attendancedateId", this.attenddenceId);

      axios
        .get(url)
        .then((response) => {
          // console.log(response);
          this.existingPlans = [];
          this.existingPlans = response.data;
          if (this.lessonList.length != 0) {
            this.existingPlans.forEach((existing) => {
              this.lessonOptions.forEach((lesson, lessonIndex) => {
                if (lesson.value == existing.id) {
                  this.lessonOptions.splice(lessonIndex, 1);
                }
              });
            });
          }
          this.fetchData = false;
        })
        .catch(() => {
          // console.log(error.response);
        });
    },

    clearResponse() {
      setTimeout(() => {
        this.attachLessonBtn.text = "Add";
        this.attachLessonBtn.disabled = false;
      }, 3000);
    },
    cancel() {
      this.lessonList = [];
      this.lessonOptions = [];
      this.lessonPlans = [];
      this.lessons = [];
      this.selectedLessons = [];
      this.existingPlans = [];
    },
  },
};
</script>

<style >
.existingPlans .list-group-item {
  border-style: none;
}
</style>
