<template>
  <div class="container">
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-container>
      <b-row>
        <b-col></b-col>
        <b-col></b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
    <div v-if="!fetchData">
      <b-row class="control-buttons">
        <b-col v-if="resourceaction == 'list'" style="text-align: left">
          <b-button
            v-if="$store.getters.defaultInstitute.userRole != 'Student'"
            variant="info"
            class="text-white sm"
            size="sm"
            @click="createGlobalgroupform"
            ><i class="fas fa-plus-square text-white mr-1"></i>Add New
            Group</b-button
          >
        </b-col>
        <b-col v-if="resourceaction != 'list'" style="text-align: right">
          <b-button
            variant="danger"
            class="text-white sm"
            size="sm"
            @click="getGlobalgroups"
            :disabled="actionButton.disabled"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
      <div v-if="resourceaction == 'list'">
        <h3 style="text-align: center; font-size: 18px !important">
          Institution Users Groups
        </h3>
        <b-row>
          <b-col sm="12">
            <table class="table table-striped table-bordered">
              <tr>
                <th>SI. No.</th>
                <th>Name</th>
                <th>Description</th>
                <th>Users Count</th>
                <th></th>
              </tr>
              <tr v-for="(globalgroup, reindex) in globalgroups" :key="reindex">
                <td>{{ reindex + 1 }}</td>
                <td>
                  {{ globalgroup.name }}
                </td>
                <td>{{ globalgroup.summary }}</td>
                <td>{{ globalgroup.users_count }}</td>
                <td>
                  <span
                    class="text-info btn m-0 p-0 sm"
                    size="sm"
                    @click="editGlobalgroupform(globalgroup)"
                    :disabled="actionButton.disabled"
                    ><i class="fas fa-pen ml-3"></i
                  ></span>
                  <span
                    class="text-info btn m-0 p-0 sm"
                    size="sm"
                    @click="deleteGlobalgroup(globalgroup.id)"
                    :disabled="actionButton.disabled"
                    ><i style="color: red" class="fas fa-trash ml-3"></i
                  ></span>
                </td>
              </tr></table
          ></b-col>
        </b-row>
      </div>
      <div v-if="resourceaction == 'create'">
        <div>
          <h3 style="text-align: center; font-size: 18px !important">
            Add new Group
          </h3>
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-1"
                label="Title:"
                label-for="input-1"
                description=""
              >
                <b-form-input
                  id="input-1"
                  v-model="globalgroup.name"
                  type="text"
                  placeholder="Enter a Title"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input-2"
                description=""
              >
                <b-form-input
                  id="input-2"
                  v-model="globalgroup.summary"
                  type="text"
                  placeholder="Enter a Description"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            :class="'m-2 pt-2 border-top border-info'"
            v-if="availableRollNumbers.length > 0"
          >
            <b-col cols="4">
              <b-form-checkbox v-model="rollnumberPrefix.enable" size="md"
                >Roll number prefixes
                <span
                  class="d-inline-block"
                  v-b-tooltip.hover
                  title="If roll numbers are like, ECU18A 01, ECU18A 02, ECU18A 03... then enter prefix 'ECU18A ' , then you can enter roll number like 01, 02, 03..."
                >
                  <i
                    style="font-size: 18px !important; color: #3c6f39fc"
                    class="fas fa-info-circle fa-xm"
                  ></i>
                </span>
              </b-form-checkbox>

              <div class="w-50 d-flex" v-if="rollnumberPrefix.enable == 1">
                <b-form-input
                  placeholder="KTUTST"
                  style="width: 100px"
                  v-model="rollnumberPrefix.text"
                ></b-form-input>
              </div>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input
                  autocomplete="off"
                  v-model="studentSelectByRollNumberText"
                  :placeholder="`Enter Student Roll No's separated by commas or Use roll number range. Example: 1-10 `"
                  @keydown.,="selectStudentByRollNumber"
                  @keypress="avoidComma"
                ></b-form-input>
              </b-input-group>
              <div v-if="studByRollNumber.length > 0">
                <b-form-tag
                  :id="'studentRollNumberTag' + roll"
                  v-for="(roll, tagIndex) in studByRollNumber"
                  :key="tagIndex"
                  class="mr-1"
                  variant="primary"
                  @remove="changeStudentByRollNumber(tagIndex)"
                  >{{ roll }}
                  <!--<b-popover :target="('studentRollNumberTag' + roll)" triggers="hover" placement="top">
                    <b-row class="mx-1">
                      <div 
                      v-for="student in avilableUsers" :key="student.id"
                        class="att-type-box unselected-att-type-box">
                        <div v-if="roll==student.roll_number"
                        @click="changeStudentByRollNumber(tagIndex)"
                        > {{student.first_name }}</div>
                      </div>
                    </b-row>
                  </b-popover>-->
                </b-form-tag>
              </div>
              <b-form-text v-else> No roll numbers added yet! </b-form-text>
            </b-col>
          </b-row>
          <b-row><b-col>Select users:</b-col></b-row>
          <b-row>
            <b-col
              >(
              <span>
                <b-button pill disabled variant="success">Selected</b-button>
              </span>
              <span>
                <b-button pill disabled variant="outline-success"
                  >Already attached</b-button
                >
              </span>
              <span>
                <b-button pill disabled variant="outline-danger"
                  >Attached to another group</b-button
                >
              </span>
              <span>
                <b-button pill disabled variant="outline-secondary"
                  >Not attached</b-button
                > </span
              >)
            </b-col>
          </b-row>
          <br />
          <b-row class="table-container">
            <b-col
              :class="{ 'd-flex justify-content-end': stIndex == 0 }"
              v-for="(Students, stIndex) in halfOfavilableUsers"
              :key="stIndex"
            >
              <table
                class="table table-bordered black-border"
                style="width: auto !important"
              >
                <thead style="position: sticky; top: 0">
                  <!-- headerAttTypes column with minimal option -->
                  <tr>
                    <th class="text-center text-white bg-dark">Roll No</th>
                    <th class="text-center text-white bg-dark">Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    @click="selectUser(guser.id, guser.roll_number)"
                    v-for="(guser, gindex) in Students"
                    :key="gindex"
                  >
                    <td>{{ guser.roll_number ? guser.roll_number : "" }}</td>
                    <td>
                      <b-button
                        pill
                        :variant="
                          globalgroupUsers.includes(guser.id)
                            ? 'success'
                            : guser.globalgroupstatus == 'attached'
                            ? 'outline-success'
                            : guser.globalgroupstatus == 'notavilable'
                            ? 'outline-danger'
                            : 'outline-secondary'
                        "
                        style="text-align: left"
                      >
                        {{ guser.first_name }} {{ guser.last_name }}
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" style="text-align: right">
              <b-button
                variant="success"
                class="text-white sm"
                size="sm"
                @click="createGlobalgroup"
                :disabled="actionButton.disabled"
                >{{ actionButton.text }}</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="resourceaction == 'edit'">
        <div>
          <h3 style="text-align: center; font-size: 18px !important">
            Edit Group {{ globalgroup.name }}
          </h3>
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-1"
                label="Title:"
                label-for="input-1"
                description=""
              >
                <b-form-input
                  id="input-1"
                  v-model="globalgroup.name"
                  type="text"
                  placeholder="Enter a Title"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input-2"
                description=""
              >
                <b-form-input
                  id="input-2"
                  v-model="globalgroup.summary"
                  type="text"
                  placeholder="Enter a Description"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-checkbox v-model="rollnumberPrefix.enable" size="md"
                >Roll number prefixes
                <span
                  class="d-inline-block"
                  v-b-tooltip.hover
                  title="If roll numbers are like, ECU18A 01, ECU18A 02, ECU18A 03... then enter prefix 'ECU18A ' , then you can enter roll number like 01, 02, 03..."
                >
                  <i
                    style="font-size: 18px !important; color: #3c6f39fc"
                    class="fas fa-info-circle fa-xm"
                  ></i>
                </span>
              </b-form-checkbox>
              <div class="w-50 d-flex" v-if="rollnumberPrefix.enable == 1">
                <b-form-input
                  placeholder="KTUTST"
                  style="width: 100px"
                  v-model="rollnumberPrefix.text"
                ></b-form-input>
              </div>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input
                  autocomplete="off"
                  v-model="studentSelectByRollNumberText"
                  :placeholder="`Enter Student Roll No's separated by commas or Use roll number range. Example: 1-10 `"
                  @keydown.,="selectStudentByRollNumber"
                  @keypress="avoidComma"
                ></b-form-input>
              </b-input-group>
              <div v-if="studByRollNumber.length > 0">
                <b-form-tag
                  :id="'studentRollNumberTag' + roll"
                  v-for="(roll, tagIndex) in studByRollNumber"
                  :key="tagIndex"
                  class="mr-1"
                  variant="primary"
                  @remove="changeStudentByRollNumber(tagIndex)"
                  >{{ roll }}
                </b-form-tag>
              </div>
              <b-form-text v-else> No roll numbers added yet! </b-form-text>
            </b-col>
          </b-row>
          <b-row><b-col>Select users:</b-col></b-row>
          <b-row>
            <b-col
              >(
              <span class="tag-button">
                <b-button pill disabled variant="success">Selected</b-button>
                <b-button pill disabled variant="outline-success"
                  >Already attached</b-button
                >
                <b-button pill disabled variant="outline-danger"
                  >Attached to another group</b-button
                >
                <b-button pill disabled variant="outline-secondary"
                  >Not attached</b-button
                > </span
              >)
            </b-col>
          </b-row>
          <br />
          <b-row class="table-container">
            <b-col
              :class="{ 'd-flex justify-content-end': stIndex == 0 }"
              v-for="(Students, stIndex) in halfOfavilableUsers"
              :key="stIndex"
            >
              <table
                class="table table-bordered black-border"
                style="width: auto !important"
              >
                <thead style="position: sticky; top: 0">
                  <!-- headerAttTypes column with minimal option -->
                  <tr>
                    <th class="text-center text-white bg-dark">Roll No</th>
                    <th class="text-center text-white bg-dark">Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    @click="selectUser(guser.id, guser.roll_number)"
                    v-for="(guser, gindex) in Students"
                    :key="gindex"
                  >
                    <td>
                      {{ guser.roll_number ? guser.roll_number : "" }}
                    </td>
                    <td>
                      <b-button
                        pill
                        :variant="
                          globalgroupUsers.includes(guser.id)
                            ? 'success'
                            : guser.globalgroupstatus == 'attached'
                            ? 'outline-success'
                            : guser.globalgroupstatus == 'notavilable'
                            ? 'outline-danger'
                            : 'outline-secondary'
                        "
                        style="text-align: left"
                      >
                        {{ guser.first_name }} {{ guser.last_name }}-{{
                          guser.globalgroupstatus
                        }}
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" style="text-align: right">
              <b-button
                variant="success"
                class="text-white sm"
                size="sm"
                @click="updateGlobalgroup"
                :disabled="actionButton.disabled"
                >{{ actionButton.text }}</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import createModule from "./ModuleCreation";
export default {
  props: [
    "model_type",
    "model_id",
    "create_with_user_ids_array",
    "create_with_summary",
    "create_with_name",
  ],

  async created() {
    this.create_with_user_ids = await this.create_with_user_ids_array;
    if (!this.create_with_user_ids) {
      this.getGlobalgroups();
    } else {
      this.createGlobalgroupform(); // create directly from parant like: create with attendamcemarking
    }
  },
  // components: {
  //   createModule: createModule,
  // },
  computed: {
    halfOfavilableUsers() {
      const middleIndex = Math.ceil(this.avilableUsers.length / 2);
      const firstHalf = this.avilableUsers.slice(0, middleIndex);
      const secondHalf = this.avilableUsers.slice(middleIndex);
      return [firstHalf, secondHalf];
    },
  },
  data() {
    return {
      availableRollNumbers: [],
      remainingIds: [],
      studentSelectByRollNumberText: "",
      studByRollNumber: [], // {id, name},...
      rollnumberPrefix: { enable: 0, text: "" },
      fetchData: false,
      resourceaction: "list", // list,create,edit
      globalgroups: [],
      globalgroup: {
        id: "",
        name: "",
        summary: "",
        model_id: this.model_id,
        model_type: this.model_type,
      },
      create_with_user_ids: [],
      globalgroupUsers: [], // users Ids that are attached to active global group (new creating global group or now editting one)
      avilableUsers: [], // available model users with status already attached to group or not
      actionButton: {
        text: "Save",
        disabled: false,
      },
    };
  },
  methods: {
      selectUser(guserId, roll_no) {
        if (this.globalgroupUsers.includes(guserId)) {
          this.globalgroupUsers.splice(this.globalgroupUsers.indexOf(guserId), 1);
          this.remainingIds = [];
          this.avilableUsers.forEach((stud) => {
            if (
              stud.roll_number == roll_no &&
              this.globalgroupUsers.includes(stud.id)
            )
              this.remainingIds.push(stud.id);
          });
          if (this.remainingIds.length == 0)
            this.studByRollNumber.splice(
              this.studByRollNumber.indexOf(roll_no),
              1
            );
        } else {
          this.globalgroupUsers.push(guserId);
          if (this.availableRollNumbers.includes(roll_no))
            if (!this.studByRollNumber.includes(roll_no))
              this.studByRollNumber.push(roll_no);
        }
    },
    changeStudentByRollNumber(index) {
      let rollNumber = this.studByRollNumber[index];
      this.studByRollNumber.splice(index, 1);
      this.avilableUsers.forEach((stud) => {
        if (
          stud.roll_number == rollNumber ||
          Number(stud.roll_number) == rollNumber
        )
          this.globalgroupUsers.splice(
            this.globalgroupUsers.indexOf(stud.id),
            1
          );
      });
    },
    
    async selectStudentByRollNumber() {
      if (this.studentSelectByRollNumberText.match(/([0-9])+([-])+([0-9])/g)) {
        let getRollNumberRange = await this.studentSelectByRollNumberText.split(
          "-"
        );
        let startRollNumber = Number(getRollNumberRange[0]);
        let endRollNumber = Number(getRollNumberRange[1]);
        if (startRollNumber > endRollNumber) {
          this.$toast.error("Please check the roll number range", {
            position: "top",
            duration: 4000,
          });
          return;
        }
        for (
          startRollNumber;
          startRollNumber <= endRollNumber;
          startRollNumber++
        ) {
          if (this.rollnumberPrefix.enable == 1) {
            if (
              this.availableRollNumbers.includes(
                this.rollnumberPrefix.text + startRollNumber
              )
            )
              this.studByRollNumber.push(
                this.rollnumberPrefix.text + startRollNumber
              );
          } else {
            if (this.availableRollNumbers.includes(`${startRollNumber}`))
              if (!this.studByRollNumber.includes(`${startRollNumber}`))
                this.studByRollNumber.push(`${startRollNumber}`);
          }
        }
      } else if (this.studentSelectByRollNumberText != "") {
        let rollNumber = "";
        if (this.rollnumberPrefix.enable == 1) {
          rollNumber =
            this.rollnumberPrefix.text + this.studentSelectByRollNumberText;
        } else {
          rollNumber = this.studentSelectByRollNumberText;
        }
        if (this.availableRollNumbers.includes(rollNumber))
          if (!this.studByRollNumber.includes(rollNumber))
            this.studByRollNumber.push(rollNumber);
      } else {
        null;
      }
      this.avilableUsers.forEach((stud) => {
        this.studByRollNumber.forEach((roll) => {
          if (!this.globalgroupUsers.includes(stud.id)) {
            if (stud.roll_number == roll) this.globalgroupUsers.push(stud.id);
          }
        });
      });
      this.studentSelectByRollNumberText = "";
    },
    avoidComma($event) {
      if (
        !/[a-zA-Z0-9 ]/.test($event.key) &&
        $event.key !== "." &&
        $event.key !== "-"
      )
        return $event.preventDefault();
    },
    async getGlobalgroups() {
      this.resourceaction = "list";
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/globalgroups?with_usercount=1&model_type=${this.model_type}&model_id=${this.model_id}`;
      await axios
        .get(url)
        .then((response) => {
          this.globalgroups = response.data;
          this.fetchData = false;
        })
        .catch(() => {
          this.$toast.error("Failed to get List", {
            position: "top",
            duration: 3000,
          });
          this.fetchData = false;
        });
    },
    deleteGlobalgroup(id) {
      this.actionButton = {
        text: "Save",
        disabled: true,
      };
      const url = this.$store.getters.getAPIKey.mainAPI + `/globalgroups/${id}`;
      this.$axios
        .delete(url)
        .then(() => {
          this.getGlobalgroups();
          this.$toast.success("Deleted successfully", {
            position: "top",
            duration: 3000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
        })
        .catch(() => {
          this.$toast.error("Failed to delete", {
            position: "top",
            duration: 3000,
          });
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
        });
    },

    async createGlobalgroupform() {
      this.globalgroupUsers = [];
      this.globalgroup = {
        id: "",
        name: "",
        summary: "",
        model_id: this.model_id,
        model_type: this.model_type,
      };
      this.resourceaction = "create";
      await this.getAvilableUsers();

      if (this.create_with_user_ids) {
        this.globalgroup = {
          id: "",
          name: this.create_with_name,
          summary: this.create_with_summary,
          model_id: this.model_id,
          model_type: this.model_type,
        };

        this.avilableUsers.forEach((user) => {
          if (this.create_with_user_ids.indexOf(user.id) > -1) {
            this.globalgroupUsers.push(user.id);
          }
        });
      }
    },
    async createGlobalgroup() {
      this.actionButton = {
        text: "Saving...",
        disabled: true,
      };
      let newGlobalgroup = {};
      const url = this.$store.getters.getAPIKey.mainAPI + `/globalgroups`;
      let responseError = false;
      await axios
        .post(url, this.globalgroup)
        .then((response) => {
          newGlobalgroup = response.data;
          this.$toast.success(newGlobalgroup.name + " created successfully", {
            position: "top",
            duration: 3000,
          });
          this.studByRollNumber = [];
          this.rollnumberPrefix.enable = "0";
          this.rollnumberPrefix.text = "";
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
        })
        .catch((error) => {
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          responseError = true;
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed to Create";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 3000,
          });
          return;
        });

      if (!responseError) {
        if (this.globalgroupUsers.length) {
          await this.syncGlobalGroupusers(newGlobalgroup.id);
        }
        await this.getGlobalgroups();
      }
      return;
    },
    async editGlobalgroupform(globalgroup) {
      this.globalgroup = globalgroup;
      this.globalgroupUsers = [];
      //fill existing users and set available users status
      await this.getGlobalgroupUsers(globalgroup.id);
      this.resourceaction = "edit";
      await this.getAvilableUsers();
    },
    async updateGlobalgroup() {
      this.actionButton = {
        text: "Saving...",
        disabled: true,
      };
      let Updatedglobalgroup_name = this.globalgroup.name;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/globalgroups/${this.globalgroup.id}`;
      let errorRes = false;
      await axios
        .put(url, this.globalgroup)
        .then(() => {
          this.$toast.success(
            Updatedglobalgroup_name + " updated successfully",
            {
              position: "top",
              duration: 3000,
            }
          );
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
        })
        .catch((error) => {
          errorRes = true;
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed to Create";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 3000,
          });
        });

      if (!errorRes) {
        await this.syncGlobalGroupusers(this.globalgroup.id);

        await this.getGlobalgroups();
      }
    },

    async getGlobalgroupUsers() {
      // this.resourceaction = "list";
      this.fetchData = true;
      // const url = this.$store.getters.getAPIKey.mainAPI + `/courseResources/${ResourceId}`;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/globalgroups/${this.globalgroup.id}/globalgroupusers`;
      await axios
        .get(url)
        .then((response) => {
          // this.globalgroupUsers = response.data;
          response.data.forEach((user) => {
            this.globalgroupUsers.push(user.id);
          });
          this.fetchData = false;
        })
        .catch(() => {
          this.$toast.error("Failed to get List", {
            position: "top",
            duration: 3000,
          });
          this.fetchData = false;
        });
    },
    async getAvilableUsers() {
      this.fetchData = true;
      this.avilableUsers = [];
      this.studByRollNumber = [];
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/globalgroups/avilableusers?model_type=${this.model_type}&model_id=${this.model_id}`;
      await axios
        .get(url)
        .then((response) => {
          this.avilableUsers = response.data;
          this.avilableUsers.forEach((user) => {
            if (user.roll_number != null && user.roll_number != "")
              this.availableRollNumbers.push(user.roll_number);
            if (this.globalgroupUsers.includes(user.id)) {
              user.globalgroupstatus = "attached";

              if (user.roll_number != null && user.roll_number != "") {
                if (!this.studByRollNumber.includes(user.roll_number)) {
                  this.studByRollNumber.push(user.roll_number);
                }
              }
            }
          });
          this.fetchData = false;
        })
        .catch(() => {
          this.$toast.error("Failed to fetch Users available to attach group", {
            position: "top",
            duration: 3000,
          });
        });
    },

    async syncGlobalGroupusers(globalgroupId) {
      this.actionButton = {
        text: "Saving...",
        disabled: true,
      };
      // let institution_users = [];
      // this.globalgroupUsers.forEach((user) => {
      //   institution_users.push(user.id);
      // });
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/globalgroups/${globalgroupId}/globalgroupusers/sync`;
      await axios
        .post(url, { institution_users: this.globalgroupUsers })
        .then(() => {
          this.$toast.success("Users updated successfully", {
            position: "top",
            duration: 3000,
          });
          this.studByRollNumber = [];
          this.rollnumberPrefix.enable = "0";
          this.rollnumberPrefix.text = "";
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
        })
        .catch(() => {
          this.actionButton = {
            text: "Save",
            disabled: false,
          };
          this.$toast.error("Failed to Update Users", {
            position: "top",
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style>
.tag-button button {
  font-size: 9px !important;
  padding: 3px;
}
</style>
