<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else>
      <b-overlay :show="loadPlan" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <div class="submitData d-flex justify-content-between mb-2">
          <b-button v-if="!createTopic" variant="primary" size="sm"
            @click="prepareLessonAndDeliveryData()">Submit</b-button>
          <b-button variant="primary" size="sm" @click="$emit('closeMultiSession')">Skip</b-button>
        </div>
        <b-form-checkbox v-model="createTopic" size="md"><span class="text-info"
            style="font-size: 14px; font-weight: 600">Didn't find your topic on the list? Click here to add a new
            topic.</span></b-form-checkbox>
        <b-form-checkbox v-model="sameTopicAllSessions" size="md"><span class="text-info"
            style="font-size: 14px; font-weight: 600">Attach Same Topic and Delivery for every
            session.</span></b-form-checkbox>
        <div v-if="createTopic">
          <CreateTopic @topicCreated="topicCreated" ref="createTopic" :courseId="courseId" />
        </div>
        <div v-else>
          <div class="lessonAndDelivery" v-if="sameTopicAllSessions">
            <b-form-group label="Attach Topics">
              <b-form @submit.prevent="attachLessons()" class="border-0">
                <b-form-group>
                  <b-list-group>
                    <b-list-group-item class="text-danger" v-if="lessonList.length == 0">No topics created for this
                      course yet!</b-list-group-item>
                    <b-list-group-item v-else>
                      <div v-if="lessonOptions.length == 0">
                        <p class="text-warning">
                          All topics has been attcahed.
                        </p>
                      </div>
                      <div v-else>
                        <b-form-checkbox-group :options="lessonOptions" v-model="selectedLessonIds"
                          stacked></b-form-checkbox-group>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>
              </b-form>
            </b-form-group>

            <b-form-group label="Attcah Delivery">
              <b-form @submit.prevent="attachDelivery()" class="border-0">
                <b-form-group>
                  <b-list-group>
                    <b-list-group-item class="text-danger" v-if="deliveryTypes.length == 0">No plans created for this
                      course yet!</b-list-group-item>
                    <b-list-group-item v-else>
                      <div v-if="deliveryTypesOptions.length == 0">
                        <p class="text-warning">
                          All lessons has been attcahed.
                        </p>
                      </div>
                      <div v-else>
                        <b-form-checkbox-group :options="deliveryTypesOptions" v-model="selectedDeliveryIds"
                          stacked></b-form-checkbox-group>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>
                <b-form-group>
                  <ClassTypes :attendanceId="null" ref="classTypes" :attendenceList="false" :muliple="true" />
                </b-form-group>
              </b-form>
            </b-form-group>
          </div>
          <div v-for="(attendence, attendenceIndex) in multipleAttendenceData" :key="attendenceIndex" v-else>
            <b-button v-b-toggle="'attendence' + attendence.id" variant="primary" size="sm" class="mt-2" block>Session -
              {{ allSessionDetails[attendence.session_id].name }}</b-button>
            <b-collapse :id="'attendence' + attendence.id" class="mt-2">
              <b-form-group label="Attach Topics">
                <b-form @submit.prevent="attachLessons()" class="border-0">
                  <b-form-group>
                    <b-list-group>
                      <b-list-group-item class="text-danger" v-if="lessonList.length == 0">No topics created for this
                        course
                        yet!</b-list-group-item>
                      <b-list-group-item v-else>
                        <div v-if="lessonOptions.length == 0">
                          <p class="text-warning">
                            All topics has been attcahed.
                          </p>
                        </div>
                        <div v-else>
                          <b-form-checkbox-group :options="lessonOptions" v-model="
                            multipleAttendence[attendence.session_id]
                              .lessonPlans
                          " stacked></b-form-checkbox-group>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>
                </b-form>
              </b-form-group>

              <b-form-group label="Attcah Delivery">
                <b-form @submit.prevent="attachDelivery()" class="border-0">
                  <b-form-group>
                    <b-list-group>
                      <b-list-group-item class="text-danger" v-if="deliveryTypes.length == 0">No plans created for this
                        course
                        yet!</b-list-group-item>
                      <b-list-group-item v-else>
                        <div v-if="deliveryTypesOptions.length == 0">
                          <p class="text-warning">
                            All lessons has been attcahed.
                          </p>
                        </div>
                        <div v-else>
                          <b-form-checkbox-group :options="deliveryTypesOptions" v-model="
                            multipleAttendence[attendence.session_id]
                              .deliveryTypes
                          " stacked></b-form-checkbox-group>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>
                </b-form>
              </b-form-group>
              <b-form-group>
                <ClassTypes :attendanceId="attendence.id" ref="classTypes" :attendenceList="false" :muliple="true" />
              </b-form-group>
            </b-collapse>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CreateTopic from "./CreateTopic.vue";
import ClassTypes from "./ClassTypes";
// import AttachDeliveryFromAttendence from "./AttachDeliveryFromAttendence";
export default {
  props: ["multipleAttendenceData", "courseId", "allSessionDetails"],
  async created() {
    this.fetchData = true;
    await this.getLessonPlans();
    await this.getdeliveryType();
    await this.prepareMultipleSessionStructure();
    this.fetchData = false;
  },
  components: {
    CreateTopic,
    ClassTypes,
    // AttachDeliveryFromAttendence,
  },
  data() {
    return {
      fetchData: false,
      createTopic: false,
      sameTopicAllSessions: false,
      loadPlan: false,
      selectedLessonIds: [],
      selectedDeliveryIds: [],
      lessonPlans: [],
      lessons: [],
      lessonOptions: [],
      deliveryTypesOptions: [],
      selectedLessons: [],
      lessonList: [],
      deliveryTypes: [],
      existingPlans: [],
      attachLessonBtn: {
        text: "Add",
        disabled: false,
      },
      multipleAttendence: {},
    };
  },
  methods: {
    async prepareMultipleSessionStructure() {
      await this.multipleAttendenceData.forEach((attendence) => {
        this.$set(this.multipleAttendence, attendence.session_id, {
          lessonPlans: [],
          deliveryTypes: [],
          attendenceId: attendence.id,
        });
      });
      this.fetchData = false;
    },
    topicCreated() {
      this.createTopic = false;
      this.getLessonPlans();
    },
    async prepareLessonAndDeliveryData() {
      this.loadPlan = true;
      let classTypes = await this.$refs.classTypes;

      if (!this.sameTopicAllSessions) {
        for (let attendence in this.multipleAttendenceData) {
          if (
            this.multipleAttendence[
            this.multipleAttendenceData[attendence].session_id
            ] &&
            this.multipleAttendence[
              this.multipleAttendenceData[attendence].session_id
            ].lessonPlans.length != 0
          ) {
            let lessonCreation = await this.attachLessons(
              this.multipleAttendence[
                this.multipleAttendenceData[attendence].session_id
              ].attendenceId,
              this.multipleAttendence[
                this.multipleAttendenceData[attendence].session_id
              ].lessonPlans
            );
            lessonCreation;
          }

          if (
            this.multipleAttendence[
            this.multipleAttendenceData[attendence].session_id
            ] &&
            this.multipleAttendence[
              this.multipleAttendenceData[attendence].session_id
            ].deliveryTypes.length != 0
          ) {
            let deliveryCreation = await this.attachDelivery(
              this.multipleAttendence[
                this.multipleAttendenceData[attendence].session_id
              ].attendenceId,
              this.multipleAttendence[
                this.multipleAttendenceData[attendence].session_id
              ].deliveryTypes
            );
            deliveryCreation;
          }

          if (classTypes.selectedClass != null) {
            await classTypes.attachClassTypeFromAttendence(
              this.multipleAttendence[
                this.multipleAttendenceData[attendence].session_id
              ].attendenceId
            );
          }

          if (attendence == this.multipleAttendenceData.length - 1) {
            this.$toast.success("Lessons & Delivery attached", {
              position: "top",
              duration: 3000,
            });
          }
        }
      }
      if (this.sameTopicAllSessions) {
        for (let attendence in this.multipleAttendenceData) {
          if (this.selectedLessonIds.length != 0) {
            let lessonCreation = await this.attachLessons(
              this.multipleAttendence[
                this.multipleAttendenceData[attendence].session_id
              ].attendenceId,
              this.selectedLessonIds
            );
            lessonCreation;
          }

          if (this.selectedDeliveryIds.length != 0) {
            let deliveryCreation = await this.attachDelivery(
              this.multipleAttendence[
                this.multipleAttendenceData[attendence].session_id
              ].attendenceId,
              this.selectedDeliveryIds
            );
            deliveryCreation;
          }

          if (classTypes.selectedClass != null) {
            await classTypes.attachClassTypeFromAttendence(
              this.multipleAttendence[
                this.multipleAttendenceData[attendence].session_id
              ].attendenceId
            );
          }

          if (attendence == this.multipleAttendenceData.length - 1) {
            this.$toast.success("Lessons & Delivery attached", {
              position: "top",
              duration: 3000,
            });
          }
        }
      }

      await this.$emit("closeMultiSession");

      this.loadPlan = false;
    },
    async getLessonPlans() {
      this.fetchData = true;
      let lessonListUrl = this.$store.getters.getAPIKey.lessonList;
      const url = lessonListUrl.replace("course_id", this.courseId);

      axios
        .get(url)
        .then((response) => {
          this.lessonList = [];
          this.lessonOptions = [];
          this.lessonList = response.data;

          this.lessonList.forEach((lesson) => {
            this.lessonOptions.push({
              value: lesson.id,
              text: lesson.lesson,
            });
          });
          if (this.deliveryTypes.length != 0) {
            this.fetchData = false;
          }

          // console.log(response);
        })
        .catch(() => {
          this.fetchData = false;
          // console.log(error.response);
        });
    },
    async getdeliveryType() {
      // this.fetchData = true;
      let url = this.$store.getters.getAPIKey.deliveryType;

      axios
        .get(url)
        .then((response) => {
          // console.log(response);
          this.deliveryTypes = [];
          this.deliveryTypesOptions = [];
          this.deliveryTypes = response.data;

          this.deliveryTypes.forEach((delivery) => {
            this.deliveryTypesOptions.push({
              value: delivery.id,
              text: delivery.name,
            });
          });
        })
        .catch((error) => {
          this.fetchData = false;
          error;
          //   console.log(error.response);
        });
    },
    async attachLessons(attendenceId, lessonPlans) {
      let status = null;
      let lessonPlan = this.$store.getters.getAPIKey.attachCourseLessonPlan;
      const url = lessonPlan.replace("attendancedateId", attendenceId);
      const attachedLessons = {
        lessonplan_ids: lessonPlans,
      };

      await axios
        .post(url, attachedLessons)
        .then(() => {
          status = true;
        })
        .catch(() => {
          status = false;
          this.clearResponse();
        });
      return status;
    },
    async attachDelivery(attendenceId, deliveryIds) {
      let status = null;
      let lessonPlan = this.$store.getters.getAPIKey.attachDeliveryType;
      const url = lessonPlan.replace("attendancedateId", attendenceId);
      const attachedLessons = {
        coursedeliverymethod_ids: deliveryIds,
      };

      await axios
        .post(url, attachedLessons)
        .then(() => {
          // console.log(response);
          status = true;
        })
        .catch(() => {
          status = false;

          // console.log(error.response);
        });
      return status;
    },

    clearResponse() {
      setTimeout(() => {
        this.attachLessonBtn.text = "Add";
        this.attachLessonBtn.disabled = false;
      }, 3000);
    },
    cancel() {
      this.lessonList = [];
      this.lessonOptions = [];
      this.lessonPlans = [];
      this.lessons = [];
      this.selectedLessons = [];
      this.existingPlans = [];
    },
  },
};
</script>

<style >
.existingPlans .list-group-item {
  border-style: none;
}
</style>
