<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else>
      <b-overlay :show="loadPlan" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <div class="submitData d-flex justify-content-between mb-2">
          <b-button v-if="!createTopic" variant="primary" size="sm"
            @click="prepareLessonAndDeliveryData()">Submit</b-button>
          <b-button variant="primary" size="sm" @click="$emit('closeLessonPlan')">Skip</b-button>
        </div>
        <b-form-checkbox v-model="createTopic" size="md"><span class="text-info"
            style="font-size: 14px; font-weight: 600">Didn't find your topic on the list? Click here to add a new
            topic.</span></b-form-checkbox>
        <div v-if="createTopic">
          <CreateTopic @topicCreated="topicCreated" ref="createTopic" :courseId="courseId" />
        </div>
        <div v-else>
          <b-alert variant="success" class="text-center font-weight-bolder" show>Topics</b-alert>
          <div class="existing" v-if="existingPlans.length != 0">
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>Existing Topics</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(plan, planIndex) in existingPlans" :key="planIndex">
                  <td class="text-align">{{ plan.lesson }}</td>
                  <td class="text-align text-center">
                    <span class="btn p-0 m-0 text-danger" @click="detachLesson(plan.id, planIndex)"><i
                        class="fas fa-trash ml-3"></i></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <b-form @submit.prevent="attachLessons()" class="border-0">
            <b-form-group>
              <b-list-group>
                <b-list-group-item class="text-danger" v-if="lessonList.length == 0">No topics created for this course
                  yet!</b-list-group-item>
                <b-list-group-item v-else>
                  <div v-if="lessonOptions.length == 0">
                    <p class="text-warning">All topics has been attcahed.</p>
                  </div>
                  <div v-else>
                    <b-form-checkbox-group :options="lessonOptions" v-model="selectedLessons"
                      stacked></b-form-checkbox-group>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-form-group>
          </b-form>
        </div>
        <div class="delivery">
          <b-alert variant="success" class="text-center font-weight-bolder" show>Delivery Types</b-alert>
          <AttachDeliveryFromAttendence ref="deliveryType" :attenddenceId="attenddenceId" :courseId="courseId" />
        </div>
        <div class="classTypes">
          <b-alert variant="success" class="text-center font-weight-bolder" show>Class Types</b-alert>
          <ClassTypes ref="classTypes" :attendanceId="attenddenceId" :attendenceList="false" />
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CreateTopic from "./CreateTopic.vue";
import AttachDeliveryFromAttendence from "./AttachDeliveryFromAttendence";
import ClassTypes from "./ClassTypes";
export default {
  props: ["attenddenceId", "courseId", "classtype_id"],
  created() {
    this.getLessonPlans();
  },
  components: {
    CreateTopic,
    AttachDeliveryFromAttendence,
    ClassTypes,
  },
  data() {
    return {
      fetchData: false,
      createTopic: false,
      loadPlan: false,
      lessonPlans: [],
      lessons: [],
      lessonOptions: [],
      selectedLessons: [],
      lessonList: [],
      existingPlans: [],
      attachLessonBtn: {
        text: "Add",
        disabled: false,
      },
    };
  },
  methods: {
    topicCreated() {
      this.createTopic = false;
      this.getLessonPlans();
    },
    async prepareLessonAndDeliveryData() {
      this.loadPlan = true;
      // let createTopic = await this.$refs.createTopic;
      let delivery = await this.$refs.deliveryType;
      let classTypes = await this.$refs.classTypes;
      if (!this.createTopic) {
        if (
          this.selectedLessons.length == 0 &&
          delivery.selectedLessons.length == 0
        ) {
          this.loadPlan = false;
          return this.$toast.error(
            "No Topic or Delivery is selected, please press skip if you don'nt want to attach lesson plan or delivery",
            {
              duration: 5000,
              position: "top",
            }
          );
        }
        if (this.selectedLessons.length != 0) {
          let createLesson = await this.attachLessons();
          if (createLesson) {
            this.$toast.success("Lessons attached", {
              position: "top",
              duration: 3000,
            });
          }
        }
        if (delivery.selectedLessons.length != 0) {
          let createDelivery = await delivery.attachDelivery();
          if (createDelivery) {
            this.$toast.success("Delivery attached", {
              position: "top",
              duration: 3000,
            });
          }
        }
        if (classTypes.selectedClass != null) {
          await classTypes.attachClassType();
        }
        await this.$emit("closeLessonPlan");
      }

      this.loadPlan = false;
    },
    async getLessonPlans() {
      this.fetchData = true;
      let lessonListUrl = this.$store.getters.getAPIKey.lessonList;
      const url = lessonListUrl.replace("course_id", this.courseId);

      axios
        .get(url)
        .then((response) => {
          this.lessonList = [];
          this.lessonOptions = [];
          this.lessonList = response.data;

          this.lessonList.forEach((lesson) => {
            this.lessonOptions.push({
              value: lesson.id,
              text: lesson.lesson,
            });
          });
          if (this.lessonList.length != 0) {
            this.getAttachedLessonPlans();
          } else {
            this.fetchData = false;
          }

          // console.log(response);
        })
        .catch(() => {
          this.fetchData = false;
          // console.log(error.response);
        });
    },
    async attachLessons() {
      let status = null;
      let lessonPlan = this.$store.getters.getAPIKey.attachCourseLessonPlan;
      const url = lessonPlan.replace("attendancedateId", this.attenddenceId);
      const attachedLessons = {
        lessonplan_ids: this.selectedLessons,
      };

      await axios
        .post(url, attachedLessons)
        .then(() => {
          status = true;
        })
        .catch(() => {
          status = false;
          this.clearResponse();
        });
      return status;
    },
    async detachLesson(lessonId, lessonIndex) {
      this.loadPlan = true;
      let lessonPlan = this.$store.getters.getAPIKey.detachCourseLessonPlan;
      const url = lessonPlan.replace("attendancedateId", this.attenddenceId);
      const attachedLessons = {
        lessonplan_ids: [],
      };
      await attachedLessons.lessonplan_ids.push(lessonId);
      axios
        .post(url, attachedLessons)
        .then(() => {
          this.existingPlans.splice(lessonIndex, 1);
          this.getLessonPlans();
          this.getAttachedLessonPlans();
          this.loadPlan = false;
          // console.log(response);
        })
        .catch(() => {
          this.loadPlan = false;

          // console.log(error.response);
        });
    },
    getAttachedLessonPlans() {
      let lessonPlan = this.$store.getters.getAPIKey
        .getAttendenceDatesLessonPlans;
      const url = lessonPlan.replace("attendancedateId", this.attenddenceId);
      axios
        .get(url)
        .then((response) => {
          this.existingPlans = [];
          this.existingPlans = response.data;
          if (this.lessonList.length != 0) {
            this.existingPlans.forEach((existing) => {
              this.lessonOptions.forEach((lesson, lessonIndex) => {
                if (lesson.value == existing.id) {
                  this.lessonOptions.splice(lessonIndex, 1);
                }
              });
            });
          }
          this.fetchData = false;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },

    clearResponse() {
      setTimeout(() => {
        this.attachLessonBtn.text = "Add";
        this.attachLessonBtn.disabled = false;
      }, 3000);
    },
    cancel() {
      this.lessonList = [];
      this.lessonOptions = [];
      this.lessonPlans = [];
      this.lessons = [];
      this.selectedLessons = [];
      this.existingPlans = [];
    },
  },
};
</script>

<style >
.existingPlans .list-group-item {
  border-style: none;
}
</style>
